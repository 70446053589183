import clsx from 'clsx';
import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import ScreenTopBarMobile from 'components/navigation/ScreenTopBarMobile';
import ListView, { ListViewItem } from 'components/scaffold/ListView';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { CSSTransition } from 'react-transition-group';
import { isNullOrUndefined } from 'util';
import PropertyResultListItem from '../search/PropertyResultListItem';
import PropertySearchResultViewProps, { SortKey } from '../search/PropertySearchResultViewProps';
import classes from './InlinePropertyList.module.scss';

registerTouchableClassNames(
  classes['btn']
);

export interface InlinePropertyListProps extends PropertySearchResultViewProps {
  pageTitle: string;
}

function toggleDirection(direction: 'DESC' | 'ASC'): 'DESC' | 'ASC' {
  return direction === 'DESC' ? 'ASC' : 'DESC';
}

export default function InlinePropertyList(props: InlinePropertyListProps) {
  const { layoutProps, onBackClick, properties, pageTitle,
    onRequestNextPage, hasMore, onSortSelect, sortKey, direction
  } = props;

  const [ sortingShow, setSortingShow ] = useState(false);
  const [ sortingOverlayIdx, setSortingOverlayIdx ] = useState(0);

  const layoutHeight = useMobileLayoutHeight();
  const { langProperty } = useLocale();

  const sortingItem = (display: string, sort: SortKey) => {
    return <div key={String(sort)} className={classes['sorting-option']} onClick={() => {
      const newDirection = sortKey === sort ? toggleDirection(direction) : direction;
      onSortSelect?.(sort, newDirection);
    }}>
      {sortKey === sort && direction === 'DESC' ? <i className="fas fa-sort-amount-down"></i> : null}
      &nbsp;{display}&nbsp;
      {sortKey === sort && direction === 'ASC' ? <i className="fas fa-sort-amount-up-alt"></i> : null}
    </div>
  };

  return <MainLayoutMobile {...layoutProps}>
    <div className={classes['root']}>
      <ScreenTopBarMobile shadow onBackClick={onBackClick} hideBackBtn={isNullOrUndefined(onBackClick)}>
        <div className={classes['top-bar-right']}>
          <div className={classes['header']}>{pageTitle}</div>
        </div>
      </ScreenTopBarMobile>
      { !isNullOrUndefined(onSortSelect) ? <div>
      <div className={classes['sorting-floating-btn']} onClick={() => setSortingOverlayIdx(1)}>
        <img src="images/sort-float-btn.png" />
      </div>
      <CSSTransition in={sortingOverlayIdx === 1} timeout={300} unmountOnExit classNames={{
        enter: classes['enter'],
        enterActive: classes['enter-active'],
        exit: classes['exit'],
        exitActive: classes['exit-active'],
      }}>
        <SwipeableViews disabled axis="y" index={sortingOverlayIdx} onChangeIndex={setSortingOverlayIdx} className={clsx([classes['sorting-panel'], classes['sorting-overlay']])} style={{ pointerEvents: sortingOverlayIdx === 1 ? 'auto' : 'none' }} containerStyle={{ zIndex: 1060, height: `${layoutHeight}` }}>
          
          <div style={{
            height: `${layoutHeight}`,
            pointerEvents: sortingOverlayIdx === 1 ? 'auto' : 'none',
          }}></div>

          <div className={classes['sorting-option-list']} style={{ height: `${layoutHeight}`, }}>
            <div onClick={() => sortingOverlayIdx === 1 && setSortingOverlayIdx(0)} className={classes['blank']}></div>
            <div className={classes['header']}>{langProperty.captionSorting}</div>
            {sortingItem(langProperty.captionDefaultSorting, null)}
            {sortingItem(langProperty.captionPriceRentSorting, 'priceOrRent')}
            {sortingItem(langProperty.captionAreaSorting, 'net')}
            {sortingItem(langProperty.captionPriceRentPerAreaSorting, 'priceOrRentPerNet')}
          </div>
        </SwipeableViews>
      </CSSTransition>     
      </div> : null
      }
      {
        properties.length > 0 ? <ListView next={onRequestNextPage} hasMore={hasMore}>
          {properties.map(property => <ListViewItem key={property.id} overrideClassName={classes['list-item-outer']}>
            <PropertyResultListItem {...property} />
          </ListViewItem>)}
        </ListView> : <div className={classes['empty-msg']}>
          <div className={classes['msg']}>{langProperty.msgNoRecord}</div>
        </div>
      }
    </div>
  </MainLayoutMobile>
}