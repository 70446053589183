import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { jumpers, useJump } from 'common/theme/jumper';
import { isNonEmpty, multiLang } from 'common/utils/utils';
import SharePanel from 'components/display-cards/SharePanel';
import { ProfileImage } from 'components/misc/ProfileImage';
import ScreenTopBarMobile from 'components/navigation/ScreenTopBarMobile';
import ListView, { ListViewItem } from 'components/scaffold/ListView';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import { ScrollContext, useCreateScrollContext } from 'components/scaffold/ScrollContext';
import VerticalScrollView from 'components/scaffold/VerticalScrollView';
import TransactionListItem, { TransactionListItemProps } from 'components/transaction/TransactionListItem';
import { TransactionTableDialog } from 'components/transaction/TransactionTable';
import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import PhotoGallery from './PhotoGallery';
import PropertyDetailBasicInfo from './PropertyDetailBasicInfo';
import { PropertyDetailProps } from './PropertyDetailProps';
import classes from './PropertyDetailScreenMobile.module.scss';
import PropertyFeaturePanel from './PropertyFeaturePanel';
import PropertyFeaturePanelSmall from './PropertyFeaturePanelSmall';
import PropertyMiniMap from './PropertyMiniMap';
import { PriceChartDialog } from 'components/price-chart/price-chart';

registerTouchableClassNames(
  classes['btn'],
  classes['contact-btn'],
  classes['item']
)

export default function PropertyDetailScreenMobile(props: PropertyDetailProps) {
  const { lang, langProperty, locale } = useLocale();
  const jump = useJump();

  const [ trnxTableShow, setTrnxTableShow ] = useState(false);
  const handleTrnxTableHide = () => {
    setTrnxTableShow(false);
  };
  const [priceChartShow, setPriceChartShow] = useState(false);
  const handlePriceChartHide = () => {
    setPriceChartShow(false);
  };

  // Scroll Context
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollContext = useCreateScrollContext(scrollRef);
  
  const { layoutProps, photos, videoLink, floorPlans, sharePanelProps, propertyDetailBasicInfo, 
    agents, lat, lng, mainFeatures, features, otherInfo, schoolNet, transactions,
    onSameBuildingSearchClick, onSameDistrictSearchClick, onContactLeadAgentClick, onAgentClick, onBackClick,
    notFound, loading, loggedIn, graphData, isChartVisible
  } = props;

  const [ showMoreInfo, setShowMoreInfo ] = useState(false);
  
  const moreInfo = () => {
    return <div>
      {features.length ? <div className={classes['more-features-container']}>
        <div className={classes['title']}>{langProperty.captionFeaturesOrFacilities}</div>
        <div className={classes['feature-panel-container']}>
          <PropertyFeaturePanelSmall features={features}/>
        </div>
      </div> : null}
      {otherInfo.length ? <div className={classes['more-features-container']}>
        <div className={classes['title']}>{langProperty.captionOtherInfo}</div>
        <div className={classes['feature-panel-container']}>
          <PropertyFeaturePanelSmall features={otherInfo}/>
        </div>
      </div> : null}
      {schoolNet.length ? <div className={classes['more-features-container']}>
        <div className={classes['title']}>{langProperty.captionSchoolNet}</div>
        <div className={classes['feature-panel-container']}>
          <PropertyFeaturePanelSmall features={schoolNet}/>
        </div>
      </div> : null}
    </div>
  };

  const mainPageContent = () => <ScrollContext.Provider value={scrollContext}>
    <div className={classes['main']} ref={scrollRef}>
      {
        (photos.length > 0 || isNonEmpty(videoLink) || floorPlans.length > 0) &&
        <div className={classes['photo-gallery-container']}>
          <PhotoGallery photoList={photos} video={videoLink} floorPlans={floorPlans}/>
        </div>
      }
      <div className={classes['property-basic-container']}>
        <PropertyDetailBasicInfo {...propertyDetailBasicInfo}/>
      </div>
      <div className={classes['property-other-info-container']}>
        <div className={classes['info-item']}>
          
        </div>
      </div>
      <div className={classes['share-panel-container']}>
        <SharePanel {...sharePanelProps}/>
      </div>
      <div className={classes['property-mini-map-container']}>
        <PropertyMiniMap lat={lat} lng={lng} defaultZoom={16}/>
      </div>
      <div className={classes['sub-header']}>{langProperty.titlePropertyFeatures}</div>
      <div className={classes['property-feature-panel-container']}>
        <PropertyFeaturePanel features={mainFeatures}/>
      </div>
      <div className={classes['more-info']}>
        {
          showMoreInfo ? moreInfo() : 
            <button className={classes['btn']} onClick={() => setShowMoreInfo(true)}>
              {langProperty.captionMorePropertyInfo}
            </button>
        }
      </div>
      <div className={classes['other-property-action-container']}>
        <button className={classes['btn']} onClick={onSameBuildingSearchClick}>
          <i className="fas fa-search"/><div>{langProperty.captionOtherPropertiesForSameBuilding}</div>
        </button>
        <button className={classes['btn']} onClick={onSameDistrictSearchClick}>
          <i className="fas fa-search"/><div>{langProperty.captionOtherPropertiesForSameDistrict}</div>
        </button>
      </div>
      {
        agents.length !== 0 ? <div>
          <div className={classes['sub-header']}>{langProperty.titlePropertyAgents}</div>
          <div><VerticalScrollView>
            <div className={classes['agent-list-container']}>
              {agents.map(agent => <div key={agent.id} className={classes['item']} onClick={() => onAgentClick?.(agent.id)}>
                  <div className={classes['image']}><ProfileImage src={agent.photoFilename} width={'3.125rem'} /></div>
                  <div>{multiLang(locale, agent.chineseName, agent.englishName)}</div>
                </div>)}
            </div>
          </VerticalScrollView></div>
        </div> : null
      }
      <TransactionTableDialog 
        show={trnxTableShow}
        onHide={handleTrnxTableHide}
        {...props.transactionList}
      />
      <PriceChartDialog
        show={priceChartShow}
        onHide={handlePriceChartHide}
        graphData={graphData}
      />
      {
        transactions.length !== 0 ? <div>
          <div className={clsx(classes['trnx-header'])}>
            <div className={classes['sub-header']}>{langProperty.titleRecentTransactions}</div>
            {/* <div className={classes['sub-header']}></div> */}
            <div>
              {
                isChartVisible ?
                  <Button variant="muted" onClick={() => {
                    if (!loggedIn) {
                      jump(jumpers.toLogin());
                      return;
                    }
                    setPriceChartShow(true);
                  }}><i className="fas fa-chart-line fa-lg"></i>
                  </Button> : null
              }

            <Button variant="pas-green" onClick={() => {
              if (!loggedIn) {
                jump(jumpers.toLogin());
                return;
              }
              setTrnxTableShow(true);
            }}>{lang.actionMore}</Button>
            </div>
          </div>
          <div className={classes['transaction-list-container']}>
            <ListView>
            {
              transactions.map((transaction: TransactionListItemProps) => <ListViewItem overrideClassName={classes['list-item-outer']}>
                <TransactionListItem {...transaction}/>
              </ListViewItem>)
            }
            </ListView>
          </div>
        </div> : null
      }
      
    </div>
  </ScrollContext.Provider>;

  const notFoundView = () => <div className={classes['not-found-view']}>
    <i className={"far fa-building " + classes['icon']}></i>
    <div className={classes['msg']}>{langProperty.msgNotExists}</div>
  </div>

  return <MainLayoutMobile tabBarIconExtraClasses={{
    'enquiry': !notFound ? classes['enquiry-btn-shadow'] : '',
  }} {...layoutProps} onNavigate={(key) => {
    if (!notFound && key === 'EnquiryDialog.Open') {
      onContactLeadAgentClick?.();
    } else {
      layoutProps.onNavigate?.(key);
    }
  }}>
    <div className={classes['property-detail-screen-mobile']}>
      <ScreenTopBarMobile onBackClick={onBackClick} shadow>
        <div className={classes['header']}>{langProperty.titlePropertyInfo}</div>
      </ScreenTopBarMobile>
      {loading ? null :
       notFound ? notFoundView() :
        mainPageContent()
      }
    </div>
  </MainLayoutMobile>
}