import clsx from 'clsx';
import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import useNativeStatusBarHeight from 'common/hooks/useNativeStatusBarHeight';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { getIOSInsectBottom } from 'common/utils/utils';
import ScreenTopBarMobile from 'components/navigation/ScreenTopBarMobile';
import TopSearchBar from 'components/navigation/TopSearchBar';
import ListView, { ListViewItem } from 'components/scaffold/ListView';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { CSSTransition } from 'react-transition-group';
import PropertyResultListItem from './PropertyResultListItem';
import classes from './PropertySearchResultScreenMobile.module.scss';
import PropertySearchResultViewProps, { SortKey } from './PropertySearchResultViewProps';




export function toggleDirection(direction: 'DESC' | 'ASC'): 'DESC' | 'ASC' {
  return direction === 'DESC' ? 'ASC' : 'DESC';
}

registerTouchableClassNames(
  classes['btn']
)

export default function PropertySearchResultScreenMobile(props: PropertySearchResultViewProps) {
  const { layoutProps, onBackClick, onSearchClick, properties,
    onRequestNextPage, hasMore, onSortSelect, onMapClick, sortKey, direction, searchType
  } = props;

  const [ sortingOverlayIdx, setSortingOverlayIdx ] = useState(0);

  const layoutHeight = useMobileLayoutHeight();
  const statusBarHeight = useNativeStatusBarHeight();
  const { langProperty } = useLocale();
  // const sortingOverlayHeight = `calc(${layoutHeight})`;

  const sortingItem = (display: string, sort: SortKey) => {
    return <div key={String(sort)} className={classes['sorting-option']} onClick={() => {
      const newDirection = sortKey === sort ? toggleDirection(direction) : direction;
      onSortSelect?.(sort, newDirection);
      setSortingOverlayIdx(0);
    }}>
      {sortKey === sort && direction === 'DESC' ? <i className="fas fa-sort-amount-down"></i> : null}
      &nbsp;{display}&nbsp;
      {sortKey === sort && direction === 'ASC' ? <i className="fas fa-sort-amount-down-alt"></i> : null}
    </div>
  };


  return <MainLayoutMobile {...layoutProps}>
    <div className={classes['root']}>
      <ScreenTopBarMobile shadow onBackClick={onBackClick}>
        <div className={classes['top-bar-right']}>
          <TopSearchBar onClick={onSearchClick} />
          <div className={classes['btn']} onClick={onMapClick}>
            {/* <i className="fas fa-map" onClick={onMapClick}></i> */}
            <i className={`fas fa-map-marked-alt ${classes['larger-icon']}`}></i>
            {/* <img src={'images/map-pin.png'} style={{ width: '1.125rem', height: '1.755rem' }} /> */}
          </div>
        </div>
      </ScreenTopBarMobile>

      {/* <div className={classes['sorting-bar']} onClick={() => setSortingOverlayIdx(1)}>
        <Button variant="pas-gray">{langProperty.captionSorting}</Button>
      </div> */}
      <div className={classes['sorting-floating-btn']} onClick={() => setSortingOverlayIdx(1)} style={{ bottom: `calc(5.725rem + ${getIOSInsectBottom()})` }}>
        <img src="images/sort-float-btn-512px.png" />
      </div>

      <CSSTransition in={sortingOverlayIdx === 1} timeout={300} unmountOnExit classNames={{
        enter: classes['enter'],
        enterActive: classes['enter-active'],
        exit: classes['exit'],
        exitActive: classes['exit-active'],
      }}>
        <SwipeableViews disabled={sortingOverlayIdx === 0} axis="y" index={sortingOverlayIdx} onChangeIndex={setSortingOverlayIdx} className={clsx([classes['sorting-panel'], classes['sorting-overlay']])} style={{ pointerEvents: sortingOverlayIdx === 1 ? 'auto' : 'none' }} containerStyle={{ zIndex: 1060, height: `calc(${layoutHeight} + ${statusBarHeight}px)` }}>
          
          <div style={{
            height: `calc(${layoutHeight} + ${statusBarHeight}px)`,
            pointerEvents: sortingOverlayIdx === 1 ? 'auto' : 'none',
          }}></div>

          <div className={classes['sorting-option-list']} style={{ height: `calc(${layoutHeight} + ${statusBarHeight}px)`, }}>
            <div onClick={() => sortingOverlayIdx === 1 && setSortingOverlayIdx(0)} className={classes['blank']}></div>
            <div className={classes['header']}>{langProperty.captionSorting}</div>
            {sortingItem(langProperty.captionDefaultSorting, null)}
            {sortingItem(searchType === 'BUY' ? langProperty.captionPriceRentSorting : langProperty.captionRentSorting, 'priceOrRent')}
            {sortingItem(langProperty.captionAreaSorting, 'net')}
            {sortingItem(searchType === 'BUY' ? langProperty.captionPriceRentPerAreaSorting : langProperty.captionRentPerAreaSorting, 'priceOrRentPerNet')}
          </div>
        </SwipeableViews>
      </CSSTransition>

      {
        properties.length > 0 ? 
        <ListView next={onRequestNextPage} hasMore={hasMore}>
          {properties.map(property => <ListViewItem key={property.id} overrideClassName={classes['list-item-outer']}>
            <PropertyResultListItem {...property} />
          </ListViewItem>)}
        </ListView> : <div className={classes['empty-msg']}>
          <div className={classes['msg']}>{langProperty.msgNoRecord}</div>
        </div>
      }

    </div>
  </MainLayoutMobile>
}