import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import useNativeStatusBarHeight from 'common/hooks/useNativeStatusBarHeight';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { isMobileUI, isNonEmpty } from 'common/utils/utils';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import SwipeableViews from 'react-swipeable-views';
import classes from './PhotoGallery.module.scss';

registerTouchableClassNames(
  classes['photo-container'],
  classes['btn']
)

const buttonBlue = '#1976d2';
const buttonGray = '#999898';

export interface PhotoGalleryProps {
  /** List of photo url */
  photoList: string[];
  video: string;
  floorPlans: string[];
  isDesktop?: boolean;
}

export default function PhotoGallery({ photoList, video, floorPlans, isDesktop }: PhotoGalleryProps) {
  const { lang } = useLocale();
  const statusBarHeight = useNativeStatusBarHeight();
  // const isDesktop = true;

  const [swipeIndex, setSwipeIndex] = useState(0);
  const handleSwipeIndex = (i: number) => {
    setSwipeIndex(i);
    setPhotoLightBoxIndex(i);
  }

  const hasPhotos = isNonEmpty(photoList);
  const hasVideo = isNonEmpty(video);
  const hasFloorPlan = isNonEmpty(floorPlans);

  const [galleryItems, setGalleryItems] = useState<'PHOTO' | 'VIDEO' | 'FLOOR_PLAN'>(hasPhotos ? 'PHOTO' : hasVideo ? 'VIDEO' : 'FLOOR_PLAN');
  const photos = galleryItems === 'PHOTO' ? photoList : floorPlans;
  const [photoLightBoxOpened, setPhotoLightBoxOpened] = useState(false);
  const [photoLightBoxIndex, setPhotoLightBoxIndex] = useState(0);

  const galleryRef = useRef<HTMLDivElement>(null);

  const isPhoto = galleryItems === 'PHOTO';
  const isVideo = galleryItems === 'VIDEO';
  const isFloorPlan = galleryItems === 'FLOOR_PLAN';
  const hasPaging = (isPhoto && photos.length > 1) || (isFloorPlan && floorPlans.length > 1);

  const videoIdBefore = video?.includes('https://www.youtube.com/watch?v=') ? video?.replace('https://www.youtube.com/watch?v=', '') : video?.replace('https://youtu.be/', '');
  const videoId = videoIdBefore?.includes('&ab_channel=') ? videoIdBefore.substring(0, videoIdBefore.indexOf('&ab_channel=')) : videoIdBefore;
  const videoPath = "https://www.youtube.com/embed/" + videoId;

  const handleChangeGalleryItems = (type: 'PHOTO' | 'VIDEO' | 'FLOOR_PLAN') => {
    setGalleryItems(type);
    handleSwipeIndex(0);
  }

  return <div>
    {photoLightBoxOpened &&
      <Lightbox
        reactModalStyle={{ overlay: { zIndex: 1500, top: statusBarHeight } }}
        mainSrc={photos[photoLightBoxIndex]}
        nextSrc={photos[(photoLightBoxIndex + 1) % photos.length]}
        prevSrc={photos[(photoLightBoxIndex + photos.length - 1) % photos.length]}
        onCloseRequest={() => setPhotoLightBoxOpened(false)}
        onMovePrevRequest={() => setPhotoLightBoxIndex((photoLightBoxIndex + photos.length - 1) % photos.length)}
        onMoveNextRequest={() => setPhotoLightBoxIndex((photoLightBoxIndex + 1) % photos.length)}
      />
    }
    <div ref={galleryRef} className={classes['photo-gallery']}>
      <SwipeableViews index={swipeIndex} disabled={false} onChangeIndex={handleSwipeIndex}>
        {
          isVideo ? <iframe
            src={videoPath}
            title="Video player"
            width="100%"
            style={{ aspectRatio: '4/3' }}
            frameBorder="0"
            allow="accelerometer; 
           autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
            : photos.map((src: string, i: number) => <div key={src} className={classes['photo-container']}
              style={{ backgroundImage: `url("${src}")` }}
              onClick={() => setPhotoLightBoxOpened(true)}
            >
              <div className={classes['photo-count']}>
                {`${i + 1} / ${photos.length}`}
              </div>
            </div>)
        }
      </SwipeableViews>
      <div className={clsx(classes['action-container'])}>
        {
          !isVideo && hasPaging ? <div className={swipeIndex != 0 ? clsx(classes['btn'], classes['page-turn']) : clsx(classes['rbtn'], classes['page-turn'])}
            onClick={() => {
              if (swipeIndex != 0) {
                handleSwipeIndex((swipeIndex + photos.length - 1) % photos.length)
              }
            }}
          >
            <i className="fas fa-angle-left" />
            <div>{lang.actionPrevPhoto}</div>
          </div> : null
        }

        <div className={classes['action-group']}>
          {
            hasPhotos && <div className={clsx(classes['btn'])}
              style={{ color: galleryItems === 'PHOTO' ? buttonBlue : buttonGray, borderColor: galleryItems === 'PHOTO' ? buttonBlue : buttonGray }}
              onClick={() => {
                if (hasFloorPlan || hasVideo) {
                  handleChangeGalleryItems('PHOTO');
                }
              }}>
              <i className="fas fa-camera" />
              <div>{lang.captionPhoto}</div>
            </div>
          }
          {
            hasVideo && <div className={clsx(classes['btn'])}
              style={{ color: galleryItems === 'VIDEO' ? buttonBlue : buttonGray, borderColor: galleryItems === 'VIDEO' ? buttonBlue : buttonGray }}
              onClick={() => {
                if (hasFloorPlan || hasPhotos) {
                  handleChangeGalleryItems('VIDEO');
                }
              }}>
              <i className="fas fa-video" />
              <div>{lang.captionVideo}</div>
            </div>
          }
          {
            hasFloorPlan && <div className={clsx(classes['btn'])}
              style={{ color: galleryItems === 'FLOOR_PLAN' ? buttonBlue : buttonGray, borderColor: galleryItems === 'FLOOR_PLAN' ? buttonBlue : buttonGray }}
              onClick={() => {
                if (hasPhotos || hasVideo) {
                  handleChangeGalleryItems('FLOOR_PLAN');
                }
              }}>
              <i className="fas fa-puzzle-piece" />
              <div>{lang.captionFloorPlan}</div>
            </div>
          }
        </div>

        {
          !isVideo && hasPaging ? <div className={swipeIndex != photos.length - 1 ? clsx(classes['btn'], classes['page-turn']) : clsx(classes['rbtn'], classes['page-turn'])}
            onClick={() => {
              if (swipeIndex != photos.length - 1) {
                handleSwipeIndex((swipeIndex + 1) % photos.length)
              }
            }}
          >
            <div>{lang.actionNextPhoto}</div>
            <i className="fas fa-angle-right" />
          </div> : null
        }

      </div>
    </div>
  </div>
}