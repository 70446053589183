// price-chart saga

import { ApiError, ApiResult } from "api";
import { priceChartApi } from "api/priceChartApi";
import { PriceChartAggregateDTO } from "common/dto/PriceChartAggregateDTO";
import { IRootState } from "reducers";
import { LocaleState } from "reducers/locale";
import { priceChartActions } from "reducers/price-chart";
import { call, put, select, takeLeading } from "redux-saga/effects";
import { PASSagaContext } from "sagas";
import { ActionType, getType } from "typesafe-actions";
import { apiTaskWrapper } from "./saga-commons";



export function* watchPriceChartFetchRequested(context: PASSagaContext) {
    yield takeLeading(
        getType(priceChartActions.doFetch),
        apiTaskWrapper(fetchPriceChart),
        context,
    );
}

export function* fetchPriceChart(context: PASSagaContext, action: ActionType<typeof priceChartActions['doFetch']>) {
    const { token } = yield select((state: IRootState) => state.login);
    const { langProperty }: LocaleState = yield select((state: IRootState) => state.locale);

    const { data, error }: ApiResult<PriceChartAggregateDTO[]> = yield call(priceChartApi.getPriceChart,
        action.payload.propertyNo,
        token
    );
    if (error) {
        if (ApiError.isErrorCode(error, 'ERR_NOT_FOUND')) {
            yield put(priceChartActions.update({ graphData: {}, isChartVisible: false }));
          } else {
            throw ApiError.of(error!);
          }
    } else {
        if (data?.length ?? 0 > 0) {
            // only generate graph data if property has valid data point for graph

            for (var i = data!.length - 1; i >= 0; i--) {
                // to make the graph ends with non zero pricePerFeet data point
                if (data![i].pricePerFeet === 0) {
                    data?.pop();
                } else {
                    break;
                }
            }

            const graphDataPointmap = new Map();
            var validDataPoint = 0;
            data!.map(x => graphDataPointmap.set(x.year, x.pricePerFeet));
            var xAxisLabelList = Array.from({ length: 10 }, (_, i) => data![data!.length - 1].year - 9 + i);
            xAxisLabelList.forEach(xAxisLabel => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                graphDataPointmap.get(xAxisLabel) > 0 ? validDataPoint++ : null
            });
            var dataSetsDataList = Array.from({ length: 10 }, (_, i) => graphDataPointmap.has(xAxisLabelList[i]) ? graphDataPointmap.get(xAxisLabelList[i]) : 0);
            const graphdata = {
                labels: xAxisLabelList,
                datasets: [
                    {
                        data: dataSetsDataList,
                        fill: false,
                        borderColor: 'rgb(0,32,90)',
                        backgroundColor: 'rgb(0,32,90)',
                        label: langProperty.titleTenYearsPricePerFeet,
                        tension: 0.1
                    },
                ],
            };
            yield put(priceChartActions.update({ graphData: graphdata, isChartVisible: validDataPoint > 1 }));
        }
    }
}

export default [
    watchPriceChartFetchRequested,
];