import { ApiResult, BASE_URL } from "api";
import { PriceChartAggregateDTO } from "common/dto/PriceChartAggregateDTO";

export const priceChartApi = {

    getPriceChart: async (propertyNumber: any = {}, token: string): Promise<ApiResult<PriceChartAggregateDTO>> => {
        try {
            const response = await fetch(`${BASE_URL}/ca/priceChart/${propertyNumber}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return await response.json();
        } catch (e) {
            return { error: String(e) };
        }
    },
}