import clsx from 'clsx';
import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import PropertyResultListItem, { PropertyResultListItemProps } from 'components/property/search/PropertyResultListItem';
import SavedSearchListItem, { SavedSearchListItemProps } from 'components/property/search/SavedSearchListItem';
import ListView, { ListViewItem } from 'components/scaffold/ListView';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import { BaseLayoutProps } from 'components/scaffold/MainLayoutProps';
import React, { useEffect, useMemo, useState } from 'react';
import classes from './BookmarkScreenMobile.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { format } from 'util';
import { saveSearchActions } from 'reducers/save-search';
import { homeActions } from 'reducers/home';

registerTouchableClassNames(
  classes['tab']
);

export interface BookmarkScreenMobileProps {
  layoutProps: BaseLayoutProps;
  properties: PropertyResultListItemProps[];
  savedCriterias: SavedSearchListItemProps[];
}

export function BookmarkList(props: { properties: PropertyResultListItemProps[]; }) {
  const { properties } = props;
  const { langProperty } = useLocale();
  const dispatch = useDispatch();
  dispatch(homeActions.updateSearchType('BUYRENT'));

  return properties.length > 0 ? <ListView>
    {properties.map(property => <ListViewItem key={property.id} overrideClassName={classes['list-item-outer']}>
      <PropertyResultListItem {...property} />
    </ListViewItem>)}
  </ListView> : <div className={classes['empty-msg']}>
    <div className={classes['msg']}>{langProperty.msgNoRecord}</div>
  </div>
}

export function SavedSearchList(props: { savedCriterias: SavedSearchListItemProps[]; isOpen: boolean; }) {
  const { savedCriterias, isOpen } = props;
  const { langProperty } = useLocale();
  const dispatch = useDispatch();
  dispatch(homeActions.updateSearchType('BUYRENT'));

  const LATEST_N_SAVED_SEARCH_CRITERIA = useSelector((state: IRootState) => state.systemSettings?.ClientApp.PROPERTY_STOCK.LATEST_N_SAVED_SEARCH_CRITERIA);

  const latestSaveSearchShown = useMemo(() => format(
    langProperty.captionLatestSaveSearchShown,
    LATEST_N_SAVED_SEARCH_CRITERIA,
  ), [LATEST_N_SAVED_SEARCH_CRITERIA, langProperty]);

  useEffect(() => {
    if (isOpen) dispatch(saveSearchActions.fetchSavedSearchCriterias());
  }, [dispatch, savedCriterias.length, isOpen]);

  return savedCriterias.length > 0 ?
    <><div className={classes['latestSaveSearch']}>{latestSaveSearchShown} <br /> {langProperty.hintForDelSaveSearch}</div>
      <ListView>
      {savedCriterias.map(criteria => <ListViewItem key={''} overrideClassName={classes['list-item-outer']}>
        <SavedSearchListItem {...criteria} />
      </ListViewItem>)}
      </ListView>
    </>
    : <div className={classes['empty-msg']}>
    <div className={classes['msg']}>{langProperty.msgNoRecord}</div>
  </div>
}

export default function BookmarkScreenMobile(props: BookmarkScreenMobileProps) {
  const { layoutProps, properties, savedCriterias } = props;

  const { langProperty } = useLocale();

  const [ tab, setTab ] = useState<'bookmark' | 'savedSearch'>('bookmark');
  const dispatch = useDispatch();
  dispatch(homeActions.updateSearchType('BUYRENT'));

  return <MainLayoutMobile {...layoutProps}>
    <div className={classes['root']}>
      <div className={classes['top-bar']}>
        <div className={classes['tab-container']}>
          <div className={clsx({
            [classes['tab']]: true,
            [classes['active']]: tab === 'bookmark'
          })} onClick={() => setTab('bookmark')}>{langProperty.titleBookmarkList}</div>
          <div className={clsx({
            [classes['tab']]: true,
            [classes['active']]: tab === 'savedSearch'
          })} onClick={() => setTab('savedSearch')}>{langProperty.titleSaveSearch}</div>
        </div>
      </div>   
      {tab === 'bookmark' && <BookmarkList properties={properties} />}
      {tab === 'savedSearch' && <SavedSearchList savedCriterias={savedCriterias} isOpen />}
    </div>
  </MainLayoutMobile>
}