import useLocale from 'common/hooks/useLocale';
import { useEffect } from "react";

export default function usePageTitle(topicTitle: string | undefined) {
  const {lang} = useLocale();
  const DEFAULT_TITLE = lang.pageTitle;
  useEffect(() => {
    if (topicTitle) {
      document.title = `${topicTitle}｜${DEFAULT_TITLE}`;
    } else {
      document.title = `${DEFAULT_TITLE}`;
    }

    return () => {
      document.title = `${DEFAULT_TITLE}`;
    };
  }, [ topicTitle, DEFAULT_TITLE ]);
}