import { ActionType, createAction, createReducer } from 'typesafe-actions';
export interface PriceChartViewModel {
    labels: number[];
    datasets: {
        data: number[];
        backgroundColor?: string | string[];
        fill: boolean;
        label: string;
        maxBarThickness?: number;
    }[];
}

export interface PriceChartState {
    graphData: Partial<PriceChartViewModel>;
    isChartVisible: boolean;
}

const initialState = (): PriceChartState => ({
    graphData: {},
    isChartVisible: false
});


export const priceChartActions = {
    doFetch: createAction('PriceChart.FetchRequested')<{ propertyNo: string }>(),
    update: createAction('PriceChart.Updated')<{ graphData: Partial<PriceChartViewModel>, isChartVisible: boolean }>(),
};

export type PriceChartActions = ActionType<typeof priceChartActions>;

export const priceChartDetailReducer = createReducer<PriceChartState, PriceChartActions>(initialState())
    .handleAction(priceChartActions.doFetch, (state) => ({
        ...state,
    }))
    .handleAction(priceChartActions.update, (state, { payload }) => ({
        ...state,
        graphData: payload.graphData,
        isChartVisible: payload.isChartVisible,
    }))
    ;

