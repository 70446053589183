import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { multiLang, multiLangNum } from 'common/utils/utils';
import FormTextField from 'components/form-controls/FormTextField';
import ScreenTopBarMobile from 'components/navigation/ScreenTopBarMobile';
import { useAlertConfirmDialog } from 'components/overlay/AlertConfirmDialog';
import MainLayoutMobile from 'components/scaffold/MainLayoutMobile';
import { ScrollContext, useCreateScrollContext } from 'components/scaffold/ScrollContext';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import Collapse from 'react-bootstrap/esm/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import { areaRangeMapping, priceRangeMapping10k, rentRangeMapping } from 'reducers/property-list';
import { saveSearchActions } from 'reducers/save-search';
import LargeSlider from './LargeSlider';
import MoreOptions from './MoreOptions';
import MultiSelectChips from './MultiSelectChips';
import MultiSelectView from './MultiSelectView';
import Numbering from './Numbering';
import SaveSearchDialog from './SaveSearchDialog';
import classes from './SearchScreenLayoutMobile.module.scss';
import { SearchScreenProps } from './SearchScreenProps';
import SelectDistrictView from './SelectDistrictView';
import YesNoOptions from './YesNoOptions';
import { homeActions } from 'reducers/home';



registerTouchableClassNames(
	classes['buy-rent-option-btn'],
	classes['action-button'],
)

export function SearchViewMobile(props: Omit<SearchScreenProps, 'layoutProps'>) {
  const { form, onSearchClick, onBackClick, onResetClick, onSaveClick, onSaveSubmit, bindSearchTitle, hideActionButtons, titleOverride,
    districtHkiOptions, districtKltOptions, districtNtOptions, usageOptions, facingOptions, decoOptions, viewOptions, otherFeatureOptions, primarySchoolNetOptions, secondarySchoolNetOptions, clubHouseFacilitiesOptions, otherOptions } = props;
  const mobileLayoutHeight = useMobileLayoutHeight();
  const { lang, langProperty, langHome, locale } = useLocale();
  const dispatch = useDispatch();

  // Scroll Context
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollContext = useCreateScrollContext(scrollRef);

  const [ openCollapse, setOpenCollapse ] = useState(false);
  
  const { openSaveDialog, openSaveSuccessDialog } = (useSelector((state: IRootState) => state.saveSearch) ?? { openSaveDialog: false, openSaveSuccessDialog: false });
  const alertSaveCriteriaSuccessDialog = useAlertConfirmDialog();

  useEffect(() => {
    if (openSaveSuccessDialog) {
      alertSaveCriteriaSuccessDialog.confirm(langProperty.msgSaveSearchSuccess, lang.actionConfirm, '', langProperty.titleSaveSearch).then(confirmed => {
        if (confirmed) {
          dispatch(saveSearchActions.closeSaveSearchSuccessDialog());
        }
      });
    }
  }, [ openSaveSuccessDialog ]);

  const priceMapping = locale === 'en' ? priceRangeMapping10k.map(n => n / 100) : priceRangeMapping10k;

  return <div className={classes['root']} >
    <SaveSearchDialog bindTitle={bindSearchTitle} onSave={onSaveSubmit} show={openSaveDialog} onHide={() => dispatch(saveSearchActions.closeSaveSearchDialog())} />
    {alertSaveCriteriaSuccessDialog.render()}
    {/* <div className={classes['part-container']}>
      <div className={classes['header-container']}>
        {langProperty.captionAdvancedSearch}
      </div>
    </div> */}

    <ScreenTopBarMobile shadow background hideBackBtn={Boolean(!onBackClick)} onBackClick={onBackClick}>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div className={classes['header-container']} style={{
          paddingRight: onBackClick ? '3.125rem' : undefined,
          paddingLeft: onResetClick ? '4.125rem' : undefined,
        }}>
          {titleOverride ?? langProperty.captionAdvancedSearch}
        </div>
        {onResetClick && <button className={classes['reset-top-btn']} onClick={onResetClick}>
          {langProperty.actionReset}
        </button>}
      </div>
    </ScreenTopBarMobile>

    <ScrollContext.Provider value={scrollContext}><div className={classes['search-screen-mobile']} ref={scrollRef}>
      
      <div className={classes['search-screen-content']}> 

        

        {/* ---------- buy rent option ---------- */}
        <div className={classes['part-container']}>
          <div  className={classes['buy-rent-option-contaner']}>
            <ButtonGroup  className={classes['buy-rent-option-group-btn']}>
              <Button
                size="sm"
                className={clsx(classes['buy-rent-option-btn'], classes['left'])}
                variant={form.field('buyOrRent').value === 'BUY' ? "pas-gray" : "outline-pas-gray"}
                onClick={() => {form.field('buyOrRent').change('BUY');dispatch(homeActions.updateSearchType('BUY')); }}> {langHome.actionBuy}
              </Button>
              <Button
                size="sm"
                className={clsx(classes['buy-rent-option-btn'], classes['right'])}
                variant={form.field('buyOrRent').value  === 'RENT' ? "pas-gray" :"outline-pas-gray"}
                onClick={() => {form.field('buyOrRent').change('RENT');dispatch(homeActions.updateSearchType('RENT')); }}>{langHome.actionRent}
              </Button>
            
            </ButtonGroup>
          </div>
        </div>

        <div className={classes['str-search-container']}>
          <div className={classes['caption']}>{langProperty.captionKeyword}</div>
          <FormTextField bind={form.field('freeTextSearch')} label={langHome.captionSearchPlaceholder} />
        </div>

        {/* ---------- district ---------- */}
        <div className={classes['district-container']} >
          <SelectDistrictView label={langProperty.captionDistrict} districtHkiOptions={districtHkiOptions} districtKltOptions={districtKltOptions} districtNtOptions={districtNtOptions} bind={form.field('district')}/>
        </div>

        {/* ---------- property usage ---------- */}
        <div className={classes['part-container']}>
          <MultiSelectChips label={langProperty.captionPropertyUsage} options={usageOptions} bind={form.field('usage')}/>
        </div>

        {/* ---------- price / rent ---------- */}
        <div className={classes['part-container']}>
          {form.field('buyOrRent').value === 'BUY' && <LargeSlider label={langProperty.captionPrice} unit={multiLang(locale, lang.u10k, lang.u1M)!} min={0} max={multiLangNum(locale, 8000, 8000/100)} step={multiLangNum(locale, 100, 100/100)} valueMapping={priceMapping} bind={form.field('priceDisplay')}/>}
          {form.field('buyOrRent').value === 'RENT' && <LargeSlider label={langProperty.captionRent} unit={''} min={0} max={100000} step={100} valueMapping={rentRangeMapping} bind={form.field('rent')}/>}
        </div>

        {/* ---------- area ---------- */}
        <div className={classes['part-container']}>
          <LargeSlider label={langProperty.captionArea} unit={lang.uFt} min={0} max={8000} step={100} valueMapping={areaRangeMapping} bind={form.field('net')}/>
        </div>

        {/* ---------- more options ---------- */}
        <div className={classes['more-options-container']} onClick={()=>{setOpenCollapse(!openCollapse);}}>
          <MoreOptions label={langProperty.captionMoreOption} openCollapse={openCollapse}/>
        </div>


        {/* ---------- collapse ---------- */}
        <Collapse in={openCollapse}>
          <div className={classes['more-options-collapse']}>
            {/* ---------- building name ---------- */}
            <div className={classes['str-search-container']}>
              <div className={classes['caption']}>{langProperty.captionBuilding}</div>
              <FormTextField bind={form.field('buildingName')} label={langProperty.captionBuilding} />
            </div>

            {/* ---------- street ---------- */}
            <div className={classes['str-search-container']}>
              <div className={classes['caption']}>{langProperty.captionStreet}</div>
              <FormTextField bind={form.field('street')} label={langProperty.captionStreet} />
            </div>

            {/* ---------- room ---------- */}
            <div className={classes['numbering-options']}>
              <Numbering label={langProperty.captionRoom} min={0} max={10} vertical={false} bind={form.field('room')} bindEnabled={form.field('roomEnabled')} />
            </div>

            {/* ---------- suite ---------- */}
            <div className={classes['numbering-options']}>
              <Numbering label={langProperty.captionSuite} min={0} max={10} vertical={false} bind={form.field('suite')} bindEnabled={form.field('suiteEnabled')}/>
            </div>

            {/* ---------- bathroom ---------- */}
            <div className={classes['numbering-options']}>
              <Numbering label={langProperty.captionBathroom} min={0} max={10} vertical={false} bind={form.field('bathroom')} bindEnabled={form.field('bathroomEnabled')}/>
            </div>

            {/* ---------- balcony ---------- */}
            <div className={classes['numbering-options']}>
              <Numbering label={langProperty.captionBalcony} min={0} max={10} vertical={false} bind={form.field('balcony')} bindEnabled={form.field('balconyEnabled')}/>
            </div>

            {/* ---------- helper room ---------- */}
            <div className={classes['helper-room-container']}>
              <YesNoOptions label={langProperty.captionHelperRoom} yesLabel={langProperty.captionHasHelperRoom} noLabel={langProperty.captionNoHelperRoom} bind={form.field('hasHelperRoom')}  bindEnabled={form.field('helperRoomEnabled')} />
            </div>

            {/* ---------- mutli select ---------- */}
            <div className={classes['mutli-select-container']}>
              <ul className="list-group list-group-flush">
                <MultiSelectView label={langProperty.captionFacing} options={facingOptions} bind={form.field('facing')}/>
                <MultiSelectView label={langProperty.captionDeco} options={decoOptions} bind={form.field('deco')}/>
                <MultiSelectView label={langProperty.captionView} options={viewOptions} bind={form.field('view')}/>
                <MultiSelectView label={langProperty.captionOtherFeatures} options={otherFeatureOptions} bind={form.field('otherFeatures')}/>
                <MultiSelectView label={langProperty.captionClubHouseFacilities} options={clubHouseFacilitiesOptions} bind={form.field('clubHouseFacilities')}/>
                <MultiSelectView label={langProperty.captionOthers} options={otherOptions} bind={form.field('others')}/>
                <MultiSelectView label={langProperty.captionPrimarySchoolNet} options={primarySchoolNetOptions} bind={form.field('primarySchoolNet')}/>
                <MultiSelectView label={langProperty.captionSecondarySchoolNet} options={secondarySchoolNetOptions} bind={form.field('secondarySchoolNet')}/>
                
              </ul>
            </div>
          </div>
        </Collapse> 


        <div className={ openCollapse ?  clsx(classes['action-button-outer-container'], classes['has-border']) : classes['action-button-outer-container']}>
          {/* <div className={classes['save-criteria-container']}>
            <Form.Group>
            <Form.Check 
              type="checkbox" 
              label={langProperty.captionSetAsDefaultSearchCriteria}
              checked={form.field('saveSearchCriteria').value ?? false}
              onChange={()=>{
                form.field('saveSearchCriteria').change(!form.field('saveSearchCriteria').value);
              }}
            />
          </Form.Group>
          </div> */}
          <div className={classes['action-buttons-container']} style={{ display: hideActionButtons ? 'none' : undefined }}>
            <Button 
              onClick={onSaveClick}
              variant="outline-dark"
              className={classes['action-button']}
            >{lang.actionSave}</Button>
            <Button 
              onClick={onSearchClick}
              variant="outline-dark"
              className={classes['action-button']}
            >{langProperty.actionSearch}
            </Button>
          </div>
        </div>

      </div>
      
    </div></ScrollContext.Provider>
    </div>;
}

export default function SearchScreenLayoutMobile(props: SearchScreenProps) {
  const { layoutProps, ...rest } = props;
  return <MainLayoutMobile {...layoutProps}>
    <SearchViewMobile {...rest} />
  </MainLayoutMobile>
}