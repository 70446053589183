import { ApiResult, BASE_URL } from 'api';
import CALoginStateDTO from 'common/dto/CALoginStateDTO';
import EmailVerificationTicketDTO from 'common/dto/EmailVerificationTicketDTO';

type LoginStateDTO = CALoginStateDTO;
type FacebookLoginRequest = {
  accessToken: string,
  email?: string,
  deviceId: string,
  code?: string,
  ticket?: string,
};

export const loginApi = {
  login: async({ username, password, deviceId }: { username: string, password: string, deviceId?: string }): Promise<ApiResult<LoginStateDTO>> => {
    const payload = new FormData();
  
    payload.append('email', username); // temporary change
    payload.append('password', password);

    if (deviceId) {
        payload.append('deviceId', deviceId);
    }

    const res = await fetch(`${BASE_URL}/ca/auth/login/normal`, {
        method: 'POST',
        body: payload,
    });
    
    return res.json();
  },

  

  googleLogin: async ({ idToken, deviceId }: { idToken: string, deviceId: string }): Promise<ApiResult<LoginStateDTO>> => {
    const payload = new FormData();
  
    payload.append('idToken', idToken);

    if (deviceId) {
        payload.append('deviceId', deviceId);
    }

    const res = await fetch(`${BASE_URL}/ca/auth/login/google`, {
        method: 'POST',
        body: payload,
    });
    
    return res.json();
  },

  appleLogin: async ({ idToken, deviceId }: { idToken: string, deviceId: string }): Promise<ApiResult<LoginStateDTO>> => {
    const payload = new FormData();
  
    payload.append('idToken', idToken);

    if (deviceId) {
        payload.append('deviceId', deviceId);
    }

    const res = await fetch(`${BASE_URL}/ca/auth/login/apple`, {
        method: 'POST',
        body: payload,
    });
    
    return res.json();
  },

  facebookLogin: async ({ accessToken, email, code, ticket, deviceId }: FacebookLoginRequest): Promise<ApiResult<LoginStateDTO>> => {
    const payload = new FormData();
  
    payload.append('accessToken', accessToken);
    if (email) {
      payload.append('email', email);
      payload.append('code', code ?? '');
      payload.append('ticket', ticket ?? '');
    }
    if (deviceId) {
        payload.append('deviceId', deviceId);
    }

    const res = await fetch(`${BASE_URL}/ca/auth/login/facebook`, {
        method: 'POST',
        body: payload,
    });
    
    return res.json();
  },

  renew: async (token: string): Promise<ApiResult<LoginStateDTO>> => {
    try {
        const payload = new FormData();
        payload.append('token', token);

        return await fetch(`${BASE_URL}/ca/auth/renew`, {
            method: 'POST',
            body: payload,
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
        }).then(res => res.json());
    } catch (e) {
        return { error: String(e) };
    }
  },

  logout: async (token: string, deviceId?: string): Promise<ApiResult<LoginStateDTO>> => {
    try {
        const payload = new FormData();
        payload.append('token', token); 
        payload.append('deviceId', deviceId ?? ''); 
        return await fetch(`${BASE_URL}/ca/auth/logout`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: payload,
        }).then(res => res.json());
    } catch (e) {
        return { error: String(e) };
    }
  },

  register: async({ type, email, password, phoneNumber, name }: { type: string, email: string, password: string, phoneNumber: string, name: string}): Promise<ApiResult<string>> => {
    const payload = new FormData();
  
    payload.append('email', email); 
    payload.append('password', password);
    payload.append('phoneNumber', phoneNumber);
    payload.append('name', name);

    const res = await fetch(`${BASE_URL}/ca/auth/register/${type}`, {
        method: 'POST',
        body: payload,
    });
    
    return res.json();
  },


  registerDevice: async (deviceId: string, deviceType: string, fcmRegToken: string): Promise<ApiResult<LoginStateDTO>> => {
    try {
        const payload = new FormData();
        payload.append('deviceId', deviceId);
        payload.append('deviceType', deviceType);
        payload.append('fcmRegToken', fcmRegToken);

        return await fetch(`${BASE_URL}/ca/auth/register-device`, {
            method: 'POST',
            body: payload,
        }).then(res => res.json());
    } catch (e) {
        return { error: String(e) };
    }
  },

  sendEmailVerification: async (email: string): Promise<ApiResult<EmailVerificationTicketDTO>> => {
    try {
        const payload = new FormData();
        payload.append('email', email);

        return await fetch(`${BASE_URL}/ca/auth/email-verification`, {
            method: 'POST',
            body: payload,
        }).then(res => res.json());
    } catch (e) {
        return { error: String(e) };
    }
  },
};

export default loginApi;