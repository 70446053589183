import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import { isNonEmpty, multiLang } from 'common/utils/utils';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';
import { notificationActions } from 'reducers/notification';
import { NotificationListItemProps } from '../../components/notification/NotificationListItem';
import NotificationListScreenLayoutMobile from '../../components/notification/NotificationListScreenLayoutMobile';
import useNotificationActionByModule from './useNotificationActionByModule';
import { homeActions } from 'reducers/home';


const NotificationListPage = () => {
  const dispatch = useDispatch();
  const { notifications, hasMore, criteria } = useSelector((state: IRootState) => state.notification);
  const DISPLAY_DATETIME_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATETIME_FORMAT);
  const layoutProps = useLayoutProps();
  const { locale } = useLocale();
  const history = useHistory();
  dispatch(homeActions.updateSearchType('BUYRENT'));
  
  useEffect(() => {
    dispatch(notificationActions.fetch({ isSwitchingPage: false }));
  }, [ dispatch ]);

//   const bindDistricts = useSimpleFormBinder(
//     criteria.districts,
//     (updated) => {
//       dispatch(agentListActions.updateCriteria({ districts: updated }));
//       dispatch(agentListActions.doFetchAgents({ isSwitchingPage: false }));
//     },
//   );

  const notificationActionByModule = useNotificationActionByModule();

  return <NotificationListScreenLayoutMobile
    layoutProps={layoutProps}
    onBackClick={() => history.goBack()}
    notifications={notifications.map((n) => ({ 
			...n, 
			title: multiLang(locale, n.titleTc, n.titleEn, n.titleSc) ?? '',
			content: multiLang(locale, n.contentTc, n.contentEn, n.contentSc) ?? '',
			date: n.date ? moment(n.date).format(DISPLAY_DATETIME_FORMAT) : '',
      onNavigate: isNonEmpty(n.link) && isNonEmpty(notificationActionByModule(n)?.['navigateUrl']) ? () => { 
        if (!n.isRead) dispatch(notificationActions.read({ id: n.id }));
        history.push(`/${notificationActionByModule(n)?.['navigateUrl']}/${n.link}`) 
      }: notificationActionByModule(n)?.['onNavigate'],
      onPrimaryAction: notificationActionByModule(n)?.['primaryAction'],
      onSecondaryAction: notificationActionByModule(n)?.['secondaryAction'],
      finished: notificationActionByModule(n)?.['finished'],
		} as NotificationListItemProps))}
    hasMore={hasMore}

    onRequestNextPage={() => dispatch(notificationActions.fetch({ isSwitchingPage: true }))}
    // onRefresh={() => dispatch(agentListActions.doFetchAgents({ isSwitchingPage: false }))}

  />;
};

export default NotificationListPage;