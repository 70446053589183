import clsx from 'clsx';
import useLocale from 'common/hooks/useLocale';
import { handlePriceDisplay, handlePriceDisplayUnit, isNonEmpty, numberWithCommas } from 'common/utils/utils';
import Image from 'components/data-display/Image';
import React from 'react';
import { isNullOrUndefined } from 'util';
import { PropertyResultListItemProps } from './PropertyResultListItem';
import classes from './PropertyResultListItemDesktop.module.scss';


export interface PropertyResultListItemDesktopProps extends PropertyResultListItemProps {
}

export default function PropertyResultListItemDesktop(props: PropertyResultListItemDesktopProps) {
  const { lang: langCommon, langProperty, locale, propertyStockStatusOptions } = useLocale();
  const { image, price, rent, gross, net, district, punchline,
    bookmarked, status, onBookmarkClick, onClick, isHotpick, propertyNo, street, buildingName, level, unpublished
  } = props;
  const isSoldOrLeased = status === 'SOLD' || status === 'LEASED';

  const showBookmark = props.showBookmark ?? true;
    
  const priceSection = () => {
		const priceDisplay = (isNullOrUndefined(price) || !isNullOrUndefined(price) && price === 0) ? '' : 
		numberWithCommas(handlePriceDisplay(price ?? 0, locale)) + handlePriceDisplayUnit(price, locale, langCommon);

		return isNonEmpty(priceDisplay) ? <div className={classes['price-section']}>
			<div className={classes['logo']}>{langProperty.sale}</div>
			<div className={classes['price']}>{`$${priceDisplay}`}</div>
		</div> : null ;
  }
  
  const rentSection = () => {
		const rentDisplay = (isNullOrUndefined(rent) || !isNullOrUndefined(rent) && rent === 0) ? '' : numberWithCommas(rent);

		return isNonEmpty(rentDisplay) ? <div className={classes['rent-section']}>
			<div className={classes['logo']}>{langProperty.rent}</div>
			<div className={classes['rent']}>{`$${rentDisplay}`}</div>
		</div> : null;
  }


	const grossDisplay = () => {
		const grossDisplay = !(gross) ? '' : (langProperty.grossAbbr + String(gross) + langCommon.uFt);

		return isNonEmpty(grossDisplay) ? <div className={classes['gross']}>{grossDisplay}</div> : null;
	}

	const netDisplay = () => {
		const netDisplay = !(net) ? '' : (langProperty.netAbbr + String(net) + langCommon.uFt);
		
		return isNonEmpty(netDisplay) ? <div className={classes['net']}>{netDisplay}</div> : null;
	}

  return <div className={clsx(classes['root'], props.unpublished && classes['unpublished'])} onClick={onClick}>
    <div className={classes['image-container']}>
      <Image 
        className={classes['image']} 
        altImage="images/home-icon.svg" 
        src={image} 
        ratio={4/3}
      />
      {
        isSoldOrLeased ? 
          <div className={clsx({ 
            [classes['status-label']]: true, 
            [classes['sold']]: status === 'SOLD',
            [classes['leased']]: status === 'LEASED',
            [classes['unpublished']]: unpublished === true 
          })}
          >
            {unpublished === true ? langProperty.captionUnpublished : propertyStockStatusOptions?.[status ?? ''] ?? status}
          </div>
        : null
      }
      {
        props.unpublished ? <div className={clsx({
          [classes['status-label']]: true,
          [classes['unpublished']]: props.unpublished,
        })} >
          {langProperty.captionUnpublished}
        </div> : null
      }
    </div>

    <div className={classes['title-section']}>
      <div>
        {street?.trim().length ? <div className={classes['title']}>{street}</div> : null}
        <div className={classes['title']}>{buildingName} {level}</div>
      </div>
      <div className={classes['bookmark-and-hotpick']}>      
      { isHotpick && <div className={classes['hotpick']}>{langProperty.captionHotpick}</div> }
      { (!isSoldOrLeased && showBookmark) || props.bookmarked ? <img className={classes['bookmark']}
          src={bookmarked ? "images/bookmark-active.png" : "images/bookmark-inactive.png"}
          onClick={(evt) => {
            evt.stopPropagation();
            onBookmarkClick?.();
          }}
        /> : null
      }
      </div>
    </div>
		
    <div className={classes['price-rent']}>
      {priceSection()}
      {rentSection()}
    </div>

    <div className={classes['area-district']}>
      <div className={classes['area']}>
        {netDisplay()}
        {
          !!(net) && !!(gross) && 
          <div className={classes['divider']}>/</div>
        }
        {grossDisplay()}
      </div>
      <div className={classes['district']}>
        {district}
      </div>
    </div>
		
		<div className={classes['punchline']}>
      ({propertyNo}) {punchline}
		</div>
  </div>
}