import { BASE_URL } from 'api';
import useComponentDidMount from 'common/hooks/useComponentDidMount';
import useSimpleFormBinder from 'common/hooks/useFormBinder';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import usePrevious from 'common/hooks/usePrevious';
import { jumpers, useJump } from 'common/theme/jumper';
import { isMobileDevice, isMobileUI, isNonEmpty, multiLang, priceFromView, priceToView } from 'common/utils/utils';
import HomeScreenLayoutDesktop from 'components/home/HomeScreenLayoutDesktop';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducers';
import { agentDetailActions } from 'reducers/agent-detail';
import { agentListActions } from 'reducers/agent-list';
import { CommonFormState, useCommonFormReducer, useFormSpec } from 'reducers/common/form';
import { getInTouchActions, getInTouchFormSpec } from 'reducers/get-in-touch';
import { homeActions } from 'reducers/home';
import { propertyListActions, PropertyStockSearchForm } from 'reducers/property-list';
import { sellerEnquiryFormActions, sellerEnquiryFormSpec } from 'reducers/seller-enquiry';
import HomeScreenLayoutMobile from '../../components/home/HomeScreenLayoutMobile';




// const bg = 'images/home-bg.png';
const bg = 'images/slide-01-1920x640.jpg';

const HomeScreen = isMobileUI() ? HomeScreenLayoutMobile : HomeScreenLayoutDesktop;

const HomePage = () => {
	const dispatch = useDispatch();
	// Home
	const { loading, latestNewsList, latestPropertiesForLeasing, latestPropertiesForSale, topAgents, topAgentComments, topFeatureTags, searchType } = useSelector((state: IRootState) => state.home);
	const layoutProps = useLayoutProps();
	// Login
	const { loggedIn, photoFilename, email, phoneNumber, name } = useSelector((state: IRootState) => state.login);
	// Noti
	const { totalCount: notificationTotalCount } = useSelector((state: IRootState) => state.notification);
	// History
	const history = useHistory();
	const jump = useJump();
	
	const { langEnquiryDialog, locale, levelOptions } = useLocale();
	const regionBundles = useLocale();
	
	const allDistrictOptions = useMemo(() => {
    return {
      ...regionBundles.districtHkiOptions,
      ...regionBundles.districtKltOptions,
      ...regionBundles.districtNtOptions,
    };
	}, [ regionBundles ]);
	
	const searchForm = useCommonFormReducer<IRootState, PropertyStockSearchForm>(
    (state: IRootState) => (state.propertyList as any) as CommonFormState<PropertyStockSearchForm>,
    propertyListActions.edit,
    propertyListActions.addFormError,
    []
	);
	
	const sellerEnquiryForm = useFormSpec(
		(state: IRootState) => state.sellerEnquiry,
		sellerEnquiryFormSpec,
		langEnquiryDialog
	);

	const getInTouchForm = useFormSpec(
		(state: IRootState) => state.getInTouch,
		getInTouchFormSpec,
		langEnquiryDialog
	);

  const existingMoreOptionSelections = isNonEmpty(searchForm.field('buildingName').value) || 
    isNonEmpty(searchForm.field('street').value) || 
    isNonEmpty(searchForm.field('roomEnabled').value) ||
    isNonEmpty(searchForm.field('suiteEnabled').value) ||
    isNonEmpty(searchForm.field('bathroomEnabled').value) ||
    isNonEmpty(searchForm.field('balconyEnabled').value) ||
    isNonEmpty(searchForm.field('helperRoomEnabled').value) ||
    isNonEmpty(searchForm.field('facing').value) ||
    isNonEmpty(searchForm.field('deco').value) ||
    isNonEmpty(searchForm.field('view').value) ||
    isNonEmpty(searchForm.field('clubHouseFacilities').value) ||
    isNonEmpty(searchForm.field('others').value) ||
    isNonEmpty(searchForm.field('primarySchoolNet').value) ||
    isNonEmpty(searchForm.field('secondarySchoolNet').value) ||
    isNonEmpty(searchForm.field('district').value) ||
    isNonEmpty(searchForm.field('usage').value) ||
    searchForm.field('net').value?.[0] !== 0 || searchForm.field('net').value?.[1] !== Infinity ||
    false
  ;

	const bindSearch = searchForm.field('freeTextSearch');
  const bindPriceRange = searchForm.field('priceDisplay');
  const bindRentRange = searchForm.field('rent');
  const bindRoomCount = searchForm.field('room');
	const bindType = useSimpleFormBinder<'BUY' | 'RENT' | 'SELLER_ENQUIRY' | 'BUYRENT'>(
		searchType,
		(updated) => dispatch(homeActions.updateSearchType(updated ?? 'BUY')),
	);

	useComponentDidMount(() => {
		bindType.change(searchForm.field('buyOrRent').value as any);
	});

	const onAdvancedSearchClick = () =>{
		// if (loggedIn) {
		if (isMobileUI()) {
			history.push('/advanced-search');
		} else {
			history.push('/map-list', {
				openMoreDialog: true,
			});
		}
		// } else {
			// history.push('/login');
			// jump(jumpers.toLogin());
		// }
	}

	useEffect(() => {
		switch (bindType.value) {
			case 'BUY':
				searchForm.field('buyOrRent').change('BUY');
				dispatch(homeActions.updateSearchType('BUY'));
				break;
			case 'RENT':
				searchForm.field('buyOrRent').change('RENT');
				dispatch(homeActions.updateSearchType('RENT'));
				break;
			default:
				// nothing to do
		}
	},  [ bindType.value ]);

	const roomShouldEnabled = searchForm.field('room').value! > 0;
	useEffect(() => {
		searchForm.field('roomEnabled').change(roomShouldEnabled);
	}, [ roomShouldEnabled ]);

	const preSearchNavigate = () => {
		dispatch(propertyListActions.fetch({ isSwitchingPage: false }));
	};

	const onSearchClick = () => {
		dispatch(propertyListActions.editHomeSearch('saveSearchCriteria', true));
		preSearchNavigate();
		history.push(isMobileUI() ? '/properties' : '/map-list');
		searchForm.field('freeTextSearch').change(searchForm.field('freeTextSearch').value?.trim());
	}

	const onMapButtonClick = () => {
		preSearchNavigate();
		history.push('/map');
	};

	useEffect(() => {
    dispatch(sellerEnquiryFormActions.edit('contact', phoneNumber ?? ''));
    dispatch(sellerEnquiryFormActions.edit('email', email ?? ''));
    dispatch(sellerEnquiryFormActions.edit('name', name));
  }, [ dispatch, email, phoneNumber ]);
	
	useComponentDidMount(() => {
		dispatch(homeActions.doFetchHome());
	});

	const prevLocale = usePrevious<string>(locale);

	const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

	useEffect(() => {
    if (prevLocale !== locale) {
      dispatch(propertyListActions.edit("priceDisplay", 
				searchForm.field("priceDisplay").value?.map(v => priceToView(priceFromView(v, prevLocale ?? locale), locale)))
      );
    }
	}, [ locale ]);
	
  const clientProfilePhotoImgSrc = loggedIn ? (isNonEmpty(photoFilename ) ? `${BASE_URL}/files/${photoFilename}` : '') : '';

  return <HomeScreen
		homeContentLoading={loading}
  	layoutProps={layoutProps || { activeNavigation: '/home' }}
  	searchPanelProps={{
			bindSearch, bindPriceRange, bindRentRange, bindRoomCount, bindType, regionBundles,
			onAdvancedSearchClick, onSearchClick, onMapButtonClick,

      existingMoreOptionSelections,

			bindName: sellerEnquiryForm.field('name'),
			bindEmail: sellerEnquiryForm.field('email'),
			bindContact: sellerEnquiryForm.field('contact'),
			bindDistricts: sellerEnquiryForm.field('districts'),
			onSellerEnquiryClick: async() => {
				const result = await sellerEnquiryForm.validateAll();
				if (result) {
					dispatch(sellerEnquiryFormActions.doEnquiry());
				}
			},
		}}
		onClientProfileClick={() => {
			if (loggedIn) {
				history.push('/customer-profile');
			} else {
				jump(jumpers.toLogin());
			}
		}}

		onShowContentSubPage={() => {
			dispatch(homeActions.doFetchHome());
		}}
  
  	backgroundImage={bg}
		// latestNews={latestNewsList}
		latestNews={latestNewsList.map(n => ({
			id: n.id, 
			imageFilename: n.coverImageUrl,
			title: n.heading,
			date: n.publishDate ? moment(n.publishDate).format(DISPLAY_DATE_FORMAT) : '',
			onClick: n.internal ?
				() => history.push(`/news/${n.id}`) :
				(n.link || n.mobileLink ? () => window.open(isMobileDevice() ? (n.mobileLink || n.link) : (n.link || n.mobileLink)) : undefined),
		}))}
  	latestPropertiesForLeasing={latestPropertiesForLeasing.map(p => ({
			...p, 
			image: p.image ? `${BASE_URL}/files/${p.image}` : '',
			street: `${multiLang(locale, p.streetZh, p.streetEn, p.streetSc) ?? ''}`,
			buildingName: `${multiLang(locale, p.buildingNameZh, p.buildingNameEn, p.buildingNameSc) ?? ''}`,
			level: `${levelOptions[p.level] ?? ''}`,
			titleEn: `${multiLang(locale, p.streetZh, p.streetEn, p.streetSc) ?? ''} ${multiLang(locale, p.buildingNameZh, p.buildingNameEn, p.buildingNameSc) ?? ''} ${levelOptions[p.level] ?? ''}`,
			titleZh: `${multiLang(locale, p.streetZh, p.streetEn, p.streetSc) ?? ''} ${multiLang(locale, p.buildingNameZh, p.buildingNameEn, p.buildingNameSc) ?? ''} ${levelOptions[p.level] ?? ''}`,
			price: 0,
			punchline: multiLang(locale, p.punchlineTC, p.punchlineEN, p.punchlineSC),
			onClick: () => history.push(`/properties/${p.propertyNo}`),
		}))}
  	latestPropertiesForSale={latestPropertiesForSale.map(p => ({
			...p, 
			image: p.image ? `${BASE_URL}/files/${p.image}` : '',
			street: `${multiLang(locale, p.streetZh, p.streetEn, p.streetSc) ?? ''}`,
			buildingName: `${multiLang(locale, p.buildingNameZh, p.buildingNameEn, p.buildingNameSc) ?? ''}`,
			level: `${levelOptions[p.level] ?? ''}`,
			titleEn: `${multiLang(locale, p.streetZh, p.streetEn, p.streetSc) ?? ''} ${multiLang(locale, p.buildingNameZh, p.buildingNameEn, p.buildingNameSc) ?? ''} ${levelOptions[p.level] ?? ''}`,
			titleZh: `${multiLang(locale, p.streetZh, p.streetEn, p.streetSc) ?? ''} ${multiLang(locale, p.buildingNameZh, p.buildingNameEn, p.buildingNameSc) ?? ''} ${levelOptions[p.level] ?? ''}`,
			rent: 0,
			punchline: multiLang(locale, p.punchlineTC, p.punchlineEN, p.punchlineSC),
			onClick: () => history.push(`/properties/${p.propertyNo}`),
		}))}
  	// topAgents={topAgents.map(p => ({
		// 	...p, image: p.photoFilename ? `${BASE_URL}/files/${p.photoFilename}` : '',
		// 	name: `${p.chineseName} ${p.englishName}`, maxRating: p.maxRating,
		// }))}
		topAgents={(topAgents.map(a => ({
      ...a,
			image: a.photoFilename ? `${BASE_URL}/files/${a.photoFilename}` : '',
			onClick: () => { 
				dispatch(agentDetailActions.openDialog({
					id: a.id,
					image: a.photoFilename ? `${BASE_URL}/files/${a?.photoFilename}` : '',
					chineseName: a.chineseName ?? '',
					englishName: a.englishName ?? '',
					rating: a.rating ?? 0,
					maxRating: a.maxRating,
					district: a.districts?.map(d => allDistrictOptions[d] ?? d)?.join(", "),
				}));
				dispatch(agentDetailActions.doFetchComments({ uid: a.id }));
			}
		})))}
		topAgentComments={(topAgentComments.map(c => ({
			...c,
			name: c.customerName,
			image: c.agentPhotoFilename ? `${BASE_URL}/files/${c.agentPhotoFilename}` : '',
		})))}
  	tags={topFeatureTags.map(t => ({
			...t, 
			tagName: multiLang(locale, t.tagNameTC, t.tagNameEN, t.tagNameSC) ?? '',
			coverPhotoFilename: t.coverPhotoFilename ? `${BASE_URL}/files/${t.coverPhotoFilename}` : '',
		}))}
		onTagClick={(tid) => {
			const tag = topFeatureTags.filter(t => t.id === tid)[0]!;
			const featuresMap = tag.features
				.map(feature => ({ [feature.type]: [ feature.value ] }))
				.reduce((a, b) => {
					const type = Object.keys(b)[0]!;
					return {
						...a,
						[type]: [ ...(a[type] ?? []), ...b[type] ],
					};
				}, {} as { [type: string]: string[] });

			dispatch(propertyListActions.reset());
			Object.keys(featuresMap).forEach((type) => {
				switch (type) {
					case 'VIEW':
						dispatch(propertyListActions.edit('view', featuresMap[type]));
						break;
					case 'DECO':
						dispatch(propertyListActions.edit('deco', featuresMap[type]));
						break;
					case 'OTHER_FEATURES':
						dispatch(propertyListActions.edit('otherFeatures', featuresMap[type]));
						break;
					case 'OTHERS':
						dispatch(propertyListActions.edit('others', featuresMap[type]));
						break;
					case 'CLUB_HOUSE_FACILITIES':
						dispatch(propertyListActions.edit('clubHouseFacilities', featuresMap[type]));
						break;
					default: 
						break;
				}
			});
			dispatch(propertyListActions.fetch({ isSwitchingPage: false }));
			//add a state 'fromTag'
			history.push(isMobileUI() ? '/properties' : '/map-list', {fromTag: true});
		}}

		getInTouchProps={{
			bindName: getInTouchForm.field('name'),
			bindPhone: getInTouchForm.field('phone'),
			bindEmail: getInTouchForm.field('email'),
			bindDistricts: getInTouchForm.field('districts'),
			bindMessage: getInTouchForm.field('message'),
			onSubmitClick: async () => {
				const result = await getInTouchForm.validateAll();
				if (result) {
					dispatch(getInTouchActions.edit(
						'districts', 
						getInTouchForm.field('districts').value?.map(v => allDistrictOptions[v])
					));
					dispatch(getInTouchActions.doRequest());
				}
			}
		}}
		
		clientProfilePhotoImgSrc={clientProfilePhotoImgSrc}

		onClickMoreSell={() => {
			dispatch(propertyListActions.reset());
			dispatch(propertyListActions.edit('buyOrRent', 'BUY'));
			history.push(isMobileUI() ? '/properties' : '/map-list');
		}}

		onClickMoreLease={() => {
			dispatch(propertyListActions.reset());
			dispatch(propertyListActions.edit('buyOrRent', 'RENT'));
			history.push(isMobileUI() ? '/properties' : '/map-list');
		}}

		onClickMoreNews={() => {
			history.push('/news');
		}}

		onClickMoreAgent={() => {
			dispatch(agentListActions.reset());
			history.push('/agents');
		}}

		notificationTotalCount={notificationTotalCount}
  />  
  
//   <HomeScreenLayoutDesktop
//   		layoutProps={layoutProps || { activeNavigation: '/home' }}
//   		searchPanelProps={{
//         bindSearch, bindPriceRange, bindRentRange, bindRoomCount, bindType, regionBundles,
//   		}}
//   		backgroundImage={bg}
//   		latestNews={latestNewsList}
//   		latestPropertiesForLeasing={latestPropertiesForLeasing.map(p => ({ ...p, image: p.image ? `${BASE_URL}/files/${p.image}` : ''}))}
//   		latestPropertiesForSale={latestPropertiesForSale.map(p => ({ ...p, image: p.image ? `${BASE_URL}/files/${p.image}` : ''}))}
//   		topAgents={topAgents.map(p => ({ ...p, image: p.photoFilename ? `${BASE_URL}/files/${p.photoFilename}` : '', name: `${p.chineseName} ${p.englishName}`, maxRating: p.maxRating }))}
//   		tags={topFeatureTags.map(p => ({ ...p, coverPhotoFilename: p.coverPhotoFilename ? `${BASE_URL}/files/${p.coverPhotoFilename}` : ''}))}
//   	/>
  

	
}

export default HomePage;