import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import LanguageScreenMobile from 'components/setting/LanguageScreenMobile';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { homeActions } from 'reducers/home';
import { localeActions } from 'reducers/locale';


const LanguagePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const languageOptions = useSelector((state: IRootState) => state.systemSettings.Language.LANGUAGE_OPTION);
  const layoutProps = useLayoutProps();
  const { locale } = useLocale();
  dispatch(homeActions.updateSearchType('BUYRENT'));

  const languageOptions = {
    'zh_HK': '繁體中文',
    'en': 'English',
    'zh_CN': '简体中文',
  };

  return <LanguageScreenMobile
    layoutProps={layoutProps}
    languageItems={Object.entries(languageOptions).map(([key, value]) => ({ key: key, caption: value, onClick: () => dispatch(localeActions.switchLocale(key))}))}
    currentLanguage={locale}
    onBackClick={() => history.goBack()}
  />

}

export default LanguagePage;