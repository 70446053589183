import useLocale from 'common/hooks/useLocale';
import SectionTitleDesktop from 'components/home/SectionTitleDesktop';
import ScrollToTopButton from 'components/misc/ScrollToTopButton';
import MainLayoutDesktop from 'components/scaffold/MainLayoutDesktop';
import { BaseLayoutProps } from 'components/scaffold/MainLayoutProps';
import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import { useMediaQuery } from 'react-responsive';
import classes from './AboutScreenDesktop.module.scss';

export interface AboutScreenDesktopProps {
  layoutProps: BaseLayoutProps;
  content?: string;
  email?: string;
  tel?: string;
  address?: string;
}

export default function AboutScreenDesktop(props: AboutScreenDesktopProps) {
  const { langSetting } = useLocale();
  const isSmallScreen = useMediaQuery({ maxWidth: 991});
  return <MainLayoutDesktop {...props.layoutProps}>
    <div className={classes['root']}>
      <SectionTitleDesktop title={langSetting.titleAboutCompany} />

      <Container>
        <Row>
          <Col md={isSmallScreen ? 12 : 6} sm={12} xs={12} className={classes['logo-col']} style={{ backgroundImage: `url("images/building-bg2.jpg")`}}>
            {/* <img className={classes['logo']} src="images/logo-max.png" /> */}
          </Col>
          <Col md={isSmallScreen ? 12 : 6} sm={12} xs={12} >
            <div className={classes['content']} dangerouslySetInnerHTML={{
              __html: props.content ?? '',
            }}>
              {/* {lineBreakString(props.content ?? '')} */}
            </div>

            <hr />

            <div className={classes['contacts']}>
              <div className={classes['item']}>
                <i className="fas fa-at" />
                <div>{props.email}</div>
              </div>
              <div className={classes['item']}>
                <i className="fas fa-phone" />
                <div>{props.tel}</div>
              </div>
              <div className={classes['item']}>
                <i className="fas fa-map-marker-alt" />
                <div>{props.address}</div>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            
          </Col>
        </Row>
      </Container>
      <ScrollToTopButton />
    </div>
  </MainLayoutDesktop>
}