import newsApi from 'api/newsApi';
import CANotificationDTO from 'common/dto/CANotificationDTO';
import useLocale from 'common/hooks/useLocale';
import { isMobileDevice } from 'common/utils/utils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { notificationActions } from 'reducers/notification';
export default function useNotificationActionByModule(): (n: CANotificationDTO) => any {
  const { lang, langNotification } = useLocale();
  const dispatch = useDispatch();
  const history = useHistory();

  return (n) => ({
    NEW_PROPERTY: {
      navigateUrl: 'properties',
      primaryAction: undefined,
      secondaryAction: undefined
    },
    PROPERTY_RECOMMENDATION: {
      navigateUrl: 'properties',
      primaryAction: undefined,
      secondaryAction: undefined
    },
    CLIENT_CLAIM_FINISHED: {
      primaryAction: n.isRead ? undefined : {
        caption: lang.actionConfirm,
        onClick: () => {
          if (!n.isRead) dispatch(notificationActions.read({ id: n.id }));
        }
      },
    },
    BOOKMARKED_PROPERTY_UPDATE: {
      onNavigate: () => {
        history.push(`/bookmarks`)
        dispatch(notificationActions.closeOverlayView());
        if (!n.isRead) dispatch(notificationActions.read({ id: n.id }));
      },
    },
    CLIENT_CLAIM_CONFIRM: {
      primaryAction: n.isRead ? undefined : {
        caption: langNotification.actionYes,
        onClick: () => {
          dispatch(notificationActions.confirmClientClaimProcess({ nid: n.id, cid: Number(n.link) }));
        }
      },

      //remove the NO NEED button as per client's request
      /*secondaryAction: n.isRead ? {
        caption: langNotification.actionProcessed,
      } : {
        caption: langNotification.actionNoNeed,
        onClick: () => {
          if (!n.isRead) dispatch(notificationActions.read({ id: n.id }));
        }
      },*/
      finished: n.isRead,
    },
    NEWS_MARKET_UPDATE: {
      navigateUrl: undefined,
      primaryAction: undefined,
      secondaryAction: undefined,
      onNavigate: () => {
        newsApi.getDetail(Number(n.link), '').then(({ data, error }) => {
          if (!error) {
            dispatch(notificationActions.read({ id: n.id }));
            if (data?.internal) {
              history.push(`/news/${n.link}`)
            } else {
              isMobileDevice() ? window.open((data?.mobileLink ?? data?.link), '_self') : window.open((data?.link ?? data?.mobileLink));

            }
          }
          dispatch(notificationActions.closeOverlayView());
        });
      },
    },
  } as { [key: string]: any })[n.module];
}