import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import FormTextField from 'components/form-controls/FormTextField';
import ModalDialog from 'components/overlay/ModalDialog';
import React from 'react';
import classes from './LoginScreenDesktop.module.scss';
import { LoginViewProps } from './LoginViewProps';
import SocialLoginButton from './SocialLoginButton';


registerTouchableClassNames(
	classes['login-btn'],
	classes['forget-password-btn'],
	classes['registration-btn']
)

export interface LoginScreenDesktopProps {
  open: boolean;
  onHide?: AnyFn;
  loginViewProps: LoginViewProps;
}

export default function LoginScreenDesktop(props: LoginScreenDesktopProps) {
  const { open, onHide, loginViewProps } = props;
  const { bindEmail, bindPassword, onLoginClick, 
		onForgotPasswordClick, onRegisterClick, onFacebookLoginClick, onGoogleLoginClick, onAppleLoginClick } = loginViewProps;

  const { langLogin } = useLocale();

  const facebookIcon = 'images/facebookIcon.png';
  const googleIcon = 'images/googleIcon.png';
  const appleIcon = 'images/appleIcon.png';
  
  return <ModalDialog title={langLogin.titleMemberLogin} show={open} onHide={onHide}>
    <form className={classes['login-screen-desktop']} autoComplete="off" onSubmit={(ev) => {
      ev.preventDefault();
    }}>
      <div className={classes['input-caption']}>{langLogin.captionEmail}</div>
      <div><FormTextField label={''} bind={bindEmail}/></div>
      <div className={classes['input-caption']}>{langLogin.captionPassword}</div>
      <div><FormTextField label={''} bind={bindPassword} type={'password'}/></div>
      <div className={classes['login-btn']}>
        <button type="submit" onClick={() => {
          onLoginClick?.();
        }}>{langLogin.actionLogin}</button>
      </div>
      <div className={classes['forget-password-or-registration-container']}>
        <button className={classes['registration-btn']} onClick={onRegisterClick}>{langLogin.actionMemberRegistration}</button>
        <button className={classes['forget-password-btn']} onClick={onForgotPasswordClick}>{langLogin.actionForgetPassword + ` ?`}</button>
      </div>
      <div className={classes['divider-container']}>
        <div className={classes['divider']}/>
      </div>
      <div className={classes['social-login-btn-container']}>
        <SocialLoginButton caption={langLogin.actionLoginWithFacebook} logoImage={facebookIcon} onClick={onFacebookLoginClick}></SocialLoginButton>
        <SocialLoginButton caption={langLogin.actionLoginWithGoogle} logoImage={googleIcon} onClick={onGoogleLoginClick}></SocialLoginButton>
        <SocialLoginButton caption={langLogin.actionLoginWithApple} logoImage={appleIcon} onClick={onAppleLoginClick}></SocialLoginButton>
      </div>
      <div className={classes['terms-and-policy-message']}>{langLogin.msgAcceptTermsAndConditionsAndPolicy}</div>
		</form>
  </ModalDialog>
}