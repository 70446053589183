import useLocale from 'common/hooks/useLocale';
import { Line } from 'react-chartjs-2';
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'common/theme/breakpoints.module.scss';
import ModalDialog from 'components/overlay/ModalDialog';
import React from 'react';
import classes from './price-chart.module.scss';
import { PriceChartViewModel } from 'reducers/price-chart';



export interface PriceChartDialogProps {
    show: boolean;
    onHide?: AnyFn;
    graphData: Partial<PriceChartViewModel>;
}

export function PriceChartDialog(props: PriceChartDialogProps) {
    const { graphData
    } = props;
    const isSmallScreen = useMediaQuery({ maxWidth: breakpoints.md });
    const { langProperty } = useLocale();


    const options = () => ({
        responsive: true,
        cornerRadius: 20,
        legend: {
            display: false
        },
        tooltips: {
            enabled: true,
            intersect: false,
            borderWidth: 1,
            borderColor: '#808080',
            backgroundColor: '#FFFFFF',
            titleFontColor: '#000000',
            bodyFontColor: '#000000',
            footerFontColor: '#000000',
            callbacks: {
                label: function (context: any) {
                    return "$" + Intl.NumberFormat().format(context.value);
                }
            }
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        precision: 0,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: langProperty.captionYears,
                    }
                }
            ],
            yAxes: [
                {
                    ticks: {
                        precision: 0,
                        beginAtZero: true,
                        callback: function (value: number) {
                            return Intl.NumberFormat().format(value);
                        }
                    },
                    stacked: true,
                    scaleLabel: {
                        display: true,
                        labelString: langProperty.captionPricePerFeet,
                    }
                }
            ]
        }
    }
    );

    return <ModalDialog hasStyle={isSmallScreen ? { height: "90%" } : { height: "100%", zIndex: 10000 }} overrideClassName={{
        overrideModal: classes['price-chart-modal'],
    }} isMobile={isSmallScreen} size="xl" show={props.show} title={langProperty.titleTenYearsPricePerFeet} onHide={props.onHide} closeButtonWhenCustomizedHeader headerChildren={(header) => <div className={classes['modal-header']}>
        {header}
    </div>} >
        <Line
            data={graphData}
            options={options()}
        />
    </ModalDialog >
}