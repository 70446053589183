import { BASE_URL } from 'api';
import fileApi from 'api/fileApi';
import CACustomerDTO, { ChangePasswordDTO } from 'common/dto/CACustomerDTO';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import usePageTitle from 'common/hooks/usePageTitle';
import NavigationBlockerDialog from 'common/utils/NavigationBlockerDialog';
import { isMobileUI, isNonEmpty, printPasswordComplexityRequirement } from 'common/utils/utils';
import CustomerProfileScreenLayoutDesktop from 'components/customer/CustomerProfileScreenLayoutDesktop';
import CustomerProfileScreenLayoutMobile from 'components/customer/CustomerProfileScreenLayoutMobile';
import ChangePasswordDialog from 'components/overlay/ChangePasswordDialog';
import deepEqual from 'deep-equal';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';
import { changePasswordFormActions, changePasswordFormValidations } from 'reducers/change-pw';
import { useCommonFormReducer } from 'reducers/common/form';
import { customerActions, customerValidations } from 'reducers/customer';
import { homeActions } from 'reducers/home';
import { loginActions } from 'reducers/login';
const CustomerProfileScreen = isMobileUI() ? CustomerProfileScreenLayoutMobile : CustomerProfileScreenLayoutDesktop;


const CustomerProfilePage = () => {
  const dispatch = useDispatch();
  const layoutProps = useLayoutProps();
  const history = useHistory();
  const { lang } = useLocale();
  const { langRegister, langClientProfile,
    districtHkiOptions, districtKltOptions, districtNtOptions, clientAppUserSalutationOptions } = useLocale();

  const { id, supportPasswordLogin } = useSelector((state: IRootState) => state.login);

  const { totalCount: notificationTotalCount } = useSelector((state: IRootState) => state.notification);
  const token = useSelector((state: IRootState) => state.login.token ?? '');
  usePageTitle(lang.navUserMgt);

  dispatch(homeActions.updateSearchType('BUYRENT'));

  const { loggedIn, deviceReady } = useSelector((state: IRootState) => state.login);

  useEffect(() => {
    if (deviceReady && loggedIn) dispatch(customerActions.doFetchCustomer());
  }, [loggedIn, deviceReady, dispatch]);


  // ------ customer profile -------------//
  const customerProfileForm = useCommonFormReducer(
    (state: IRootState) => state.customer,
    customerActions.edit,
    customerActions.addFormError,
    customerValidations(langClientProfile)
  );

  const customerProfileFormValues = useSelector((state: IRootState) => state.customer.contents);
  const originalCustomerProfileFormValues = useSelector((state: IRootState) => state.customer.originalContents);



  const unsaved = !deepEqual(originalCustomerProfileFormValues, customerProfileFormValues, { strict: true })

  const onSaveClick = () => {
    dispatch(customerActions.doUpdateCustomer({
      ...customerProfileFormValues,
    } as CACustomerDTO));
  }

  // --------- change password -----------//
  // const [ openChangePwDialog, setOpenChangePwDialog ] = useState(false);
  const socialLogin = useSelector((state: IRootState) => state.customer.contents.socialLogin);
  const changePwForm = useCommonFormReducer(
    (state: IRootState) => state.changePwForm,
    changePasswordFormActions.edit,
    changePasswordFormActions.addFormError,
    changePasswordFormValidations(langClientProfile, socialLogin)
  );

  const { open: openChangePwDialog } = useSelector((state: IRootState) => state.changePwForm);
  const changePwFormValues = useSelector((state: IRootState) => state.changePwForm.contents);

  //---------- password complexity -----------//
  const { MIN_LENGTH, MIN_LENGTH_UPPER, MIN_LENGTH_LOWER, MIN_LENGTH_NUM, MIN_LENGTH_SPECIAL } = useSelector((state: IRootState) =>
    state.systemSettings?.ClientApp?.PASSWORD) ?? {};

  const pwRequirement = printPasswordComplexityRequirement(langRegister, MIN_LENGTH, MIN_LENGTH_UPPER, MIN_LENGTH_LOWER, MIN_LENGTH_NUM, MIN_LENGTH_SPECIAL);

  //---------- profile picture -----------//
  const sizeLimit = 3 * 1024 * 1024;
  const onConfirmUploadPhoto = (output: Blob) => {
    var file = new File([output], `profile_picture_${id}.png`);
    let files = [file];
    const currentLength = isNonEmpty(customerProfileForm.field('photoFilename').value) ? 1 : 0;
    const limit = 1;
    let remainLength = limit ?? 0 - currentLength > 0 ? limit ?? 0 - currentLength : 0;
    if (remainLength === 0) return;

    const hasLimitExceededFiles = files.some(f => f.size > sizeLimit);

    if (hasLimitExceededFiles) {
      dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: lang.msgFileSizeExceed } });
      return;
    }

    Promise.all(files.slice(0, remainLength).map(file => {
      return fileApi.addFile(file, token).then(result => result.data as string)
    })).then(paths => {
      dispatch(customerActions.edit('photoFilename', paths![0]));
    });
  }



  const onDeletePhoto = () => {
    dispatch(customerActions.edit('photoFilename', undefined));
  }

  const photoImgScr = isNonEmpty(customerProfileForm.field('photoFilename').value) ? `${BASE_URL}/files/${customerProfileForm.field('photoFilename').value}` : '';
  // const photoImgScr = '';

  return <div>
    <NavigationBlockerDialog unsaved={unsaved} goBack />
    <CustomerProfileScreen
      layoutProps={layoutProps}
      regionBundles={{
        districtHkiOptions,
        districtKltOptions,
        districtNtOptions
      }}
      userSalutationOptions={clientAppUserSalutationOptions}
      email={customerProfileForm.field('email').value ?? ''}
      bindPhoneNumber={customerProfileForm.field('phoneNumber')}
      bindName={customerProfileForm.field('name')}
      bindSalutation={customerProfileForm.field('salutation')}
      bindDistrict={customerProfileForm.field('districts')}
      bindReceiveEmail={customerProfileForm.field('receiveEmail')}
      bindSubscribeNewsletter={customerProfileForm.field('subscribeNewsletter')}
      onSaveClick={() => onSaveClick()}
      onChangePasswordClick={() => dispatch(changePasswordFormActions.openDialog())}
      onBackClick={() => history.length ? history.goBack() : history.push('/home')}
      onLogoutClick={() => {
        dispatch(loginActions.logout())
        // window.location.reload();
      }}

      onConfirmUploadPhoto={onConfirmUploadPhoto}
      onDeletePhoto={onDeletePhoto}
      photoImgSrc={photoImgScr}
      notificationTotalCount={notificationTotalCount}
      supportChangePassword={supportPasswordLogin ?? false}
      unsaved={unsaved} /><ChangePasswordDialog
      open={openChangePwDialog}
      onHide={() => {
        dispatch(changePasswordFormActions.closeDialog());
        dispatch(changePasswordFormActions.reset());
      }}
      bindOldPassword={changePwForm.field('oldPassword')}
      bindNewPassword={changePwForm.field('newPassword')}
      bindConfirmPassword={changePwForm.field('confirmNewPassword')}
      onSubmitClick={async () => {
        const result = await changePwForm.validateAll();
        if (result) {
          dispatch(changePasswordFormActions.doChangePassword({ ...changePwFormValues } as ChangePasswordDTO));
        } else {
          dispatch(changePasswordFormActions.clearInput());
        }
      }}

      passwordComplexityRequirement={pwRequirement}
      socialLogin={socialLogin} />
  </div>



}

export default CustomerProfilePage;