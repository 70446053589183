import { BASE_URL, PUBLIC_URL } from 'api';
import clsx from 'clsx';
import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import breakpoints from 'common/theme/breakpoints.module.scss';
import { ProfileImage } from 'components/misc/ProfileImage';
import PopupOverlay from 'components/overlay/PopupOverlay';
import { desktopNavigationHeight, desktopNavigationRootBarHeight } from 'components/scaffold/MainLayoutDesktop';
import React, { useLayoutEffect, useRef, useState } from 'react';
import Nav from 'react-bootstrap/esm/Nav';
import Navbar from 'react-bootstrap/esm/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';
import { localeActions } from 'reducers/locale';
import { loginActions } from 'reducers/login';
import { notificationActions } from 'reducers/notification';
import { saveSearchActions } from 'reducers/save-search';
import classes from './DesktopNavigationBar.module.scss';




export interface DesktopNavigationBarProps {
	/** A key which indicates the active navigation button. */
	activeKey: string;
	/** Navigation buttons. */
	navigationButtons: {
		key: string;
		caption: React.ReactNode;
		onClick?: () => any;
	}[];
	onClickNavigationButton: (key: string) => any;
	onClickLoginOrRegisterButton: AnyFn;
	onClickCustomerProfile: AnyFn;
	loggedIn: boolean;
	email?: string;
	name?: string;
	photoFilename?: string;
	rootBarBehavior?: 'show-on-hover' | undefined;
	tabBarIconExtraClasses?: Record<string, string>;
}

const EMPTY_OBJECT = {};
const BLANK = {};

export default function DesktopNavigationBar(props: DesktopNavigationBarProps) {
	const { activeKey, navigationButtons, onClickNavigationButton, 
		onClickLoginOrRegisterButton, onClickCustomerProfile, loggedIn, email,
		name, photoFilename, rootBarBehavior, tabBarIconExtraClasses } = props;
	const { FRONTEND_CLIENT } = useSelector((state: IRootState) => state.systemSettings?.System) ?? BLANK;
	const { lang, langLogin, locale } = useLocale();
	const { totalCount: notificationTotalCount } = useSelector((state: IRootState) => state.notification ?? EMPTY_OBJECT) ?? 0;
	const dispatch = useDispatch();
	const history = useHistory();
	const isSmallScreen = useMediaQuery({ maxWidth: breakpoints.lg });
	const useShortIcon = useMediaQuery({ maxWidth: breakpoints.lg });
	const collapsed = isSmallScreen;
	// const [ collapsed, setCollapsed ] = useState(false);
	const [ expanded, setExpanded ] = useState(false);

	// contact
	const TEL = useSelector((root: IRootState) => root.systemSettings?.ClientApp?.ABOUT?.COMPANY_TEL) ?? '';
	const EMAIL = useSelector((root: IRootState) => root.systemSettings?.ClientApp?.ABOUT?.COMPANY_EMAIL) ?? '';

	// Auto Hide Behavior
	const rootBarRef = useRef<HTMLDivElement | null>(null);
	// const prevScrollpos = useRef(window.pageYOffset);
	useLayoutEffect(() => {
		if (!rootBarRef.current) {
			return;
		}

		if (rootBarBehavior === 'show-on-hover') {
			setTimeout(() => rootBarRef.current!.style.top = `calc(0px - ${desktopNavigationRootBarHeight})`, 1000);
			// rootBarRef.current!.style.top = `calc(0px - ${desktopNavigationRootBarHeight})`;
		}

		const listener = () => {
			if (rootBarBehavior === 'show-on-hover') {
				return;
			}

			const currentScrollPos = window.pageYOffset;
			if (currentScrollPos <= 100) {
				rootBarRef.current!.style.top = "0";
			} else {
				rootBarRef.current!.style.top = `calc(0px - ${desktopNavigationRootBarHeight})`;
			}
			// prevScrollpos.current = currentScrollPos;
		};

		window.addEventListener('scroll', listener);
		return () => window.removeEventListener('scroll', listener);
	}, [ rootBarBehavior ]);

	const handleRootBarMouseEnter = () => {
		if (rootBarRef.current && rootBarBehavior === 'show-on-hover') {
			rootBarRef.current!.style.top = "-0px";
		}
	}

	const handleRootBarMouseLeave = () => {
		if (rootBarRef.current && rootBarBehavior === 'show-on-hover') {
			rootBarRef.current!.style.top = `calc(0px - ${desktopNavigationRootBarHeight})`;
		}
	}

	const handleRootBarClickCapture = (ev: React.UIEvent) => {
		if (rootBarRef.current && rootBarBehavior === 'show-on-hover') {
			rootBarRef.current!.style.top = rootBarRef.current!.style.top === '0px' ?
				`calc(0px - ${desktopNavigationRootBarHeight})` : '0px';
		}
	}

	const userMgtItems = [
		{
			caption: lang.navBookmarks,
			url: '/bookmarks',
			action: () => {
				history.push('/bookmarks');
			}
		},
		{
			caption: lang.navSavedSearchCriteria,
			url: '/bookmarks',
			action: () => {
				dispatch(saveSearchActions.openSavedSearchCriteriasDialog())
			}
		},
		{
			caption: lang.actionUserProfile,
			url: '/customer-profile',
			action: () => {
				history.push('/customer-profile');
			}
		},
		{
			divider: true,
		},
		{
			caption: lang.actionLogout,
			action: () => {
				dispatch(loginActions.logout());
			}
		}
	]

	const logo = <img src= "images/logo-toolbar-long.png" alt="logo" onClick={() => onClickNavigationButton('/home')}/>;

	return <div ref={rootBarRef} id="navigation-root-bar" className={classes['root']} style={{ height: desktopNavigationHeight }} data-api-version = {FRONTEND_CLIENT?.VERSION} onMouseEnter={handleRootBarMouseEnter} onMouseLeave={handleRootBarMouseLeave} >
		<Navbar className={clsx(classes['root-bar'])}><div className={clsx('container')}>
			<div className={classes['left-node']}>
				{
					!loggedIn ? <div className={classes['login-register']} 
						onClick={onClickLoginOrRegisterButton}>{langLogin.actionLoginOrRegister}</div>
						: 
					<div className="dropdown">
						<button className={`btn ${classes['customer-profile']}`} type="button" data-toggle="dropdown">
							<div style={{ width: '1.5rem' }}><ProfileImage src={photoFilename ? BASE_URL + '/files/' + photoFilename : ''} width={'1.5rem'} /></div>
							<div style={{ marginLeft: '0.5rem' }}>
								&nbsp;
								{name || email}
							</div>
						</button>
						<div className="dropdown-menu">
							{
								userMgtItems.map(item => !item.divider ? <div className={`dropdown-item ${classes['dropdown-item']}`}
									// href={item.url ?? "#"}
									onClick={item.action}
								>
									{item.caption}
								</div> : <div className="dropdown-divider"></div>)
							}
						</div>
					</div>
				}
				{ loggedIn ? <div className={classes['noti-btn']} onClick={() => dispatch(notificationActions.openOverlayView())}>
					{ notificationTotalCount > 0 && <div className={classes['red-reminder']}><div>{notificationTotalCount > 99 ? '99+' : notificationTotalCount}</div></div> }
					<i className="far fa-envelope" />
				</div> : null}
				
				{locale === 'en' ? null : <div className={classes['language-option']} onClick={() => dispatch(localeActions.switchLocale("en"))}>
					{lang.englishAbbr}
				</div>}
				{locale === 'zh_HK' ? null : <div className={classes['language-option']} onClick={() => dispatch(localeActions.switchLocale("zh_HK"))}>
					{lang.traditionalChineseAbbr}
				</div>}
				{locale === 'zh_CN' ? null : <div className={classes['language-option']} onClick={() => dispatch(localeActions.switchLocale("zh_CN"))}>
					{lang.simplifiedChineseAbbr}
				</div>}
			</div>
			
			{!isSmallScreen && <div className={classes['contact-info']}>
				<a className={classes['contact-email']} href={`mailto:${EMAIL}`}>
					<i className="fas fa-envelope-open" />
					<div className={classes['email']}>{EMAIL}</div>
				</a>
				<a className={classes['contact-phone']} href={`tel:${TEL.replace(' ', '')}`}>
					<i className="fas fa-phone-alt" />
					<div className={classes['phone']}>{TEL}</div>
				</a>
			</div>}
		</div></Navbar>
		<div className={clsx('container', classes['divider'])}><hr /></div>
		<PopupOverlay show={expanded} animation='fade'>
			<div className={classes['menu-popup']}>
				<div className={classes['menu-header']}>
					{/* Logo */}
					<div className={classes['logo']}>
						{logo}
					</div>
					{/* Close Button */}
					<div className={classes['close-btn']} onClick={() => setExpanded(false)}>
						<i className="fas fa-times"></i>
					</div>
				</div>
				{
					navigationButtons.map(btn => <Nav.Link 
						href={btn.key.startsWith('/') ? `${PUBLIC_URL}${btn.key}` : '#'} 
						// active={btn.key === activeKey}
						onClick={(ev: any) => {
							ev.preventDefault();
							if (btn.onClick) {
								btn.onClick?.();
							} else {
								onClickNavigationButton?.(btn.key);
							}
							setExpanded(false);
						}}
					>
						<div
							className={clsx({
								[classes['caption']]: true,
								[classes['active']]: btn.key === activeKey,
								[tabBarIconExtraClasses?.[btn.key] ?? '']: tabBarIconExtraClasses && btn.key in tabBarIconExtraClasses
							})}>{btn.caption}</div>
					</Nav.Link>)
				}
				{/* <Nav.Link> */}
					<a className={classes['contact-email']} href={`mailto:${EMAIL}`}>
						<i className="fas fa-envelope-open" />
						<div className={classes['email']}>{EMAIL}</div>
					</a>
				{/* </Nav.Link> */}
				{/* <Nav.Link> */}
					<a className={classes['contact-phone']} href={`tel:${TEL}`}>
						<i className="fas fa-phone-alt" />
						<div className={classes['phone']}>{TEL}</div>
					</a>
				{/* </Nav.Link> */}
			</div>
		</PopupOverlay>
		<Navbar className={classes['navbar'] + " " + "justify-content-between"} expanded={expanded && false} expand={!collapsed} onClickCapture={handleRootBarClickCapture}>
			<div className={clsx('container')}>
				<Navbar.Brand className={classes['brand']}>
					{logo}
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
				{/* {collapsed && <div>Reserved for Others</div>} */}
				{/* <Button className={"navbar-toggler" + " " + classes['collapse-toggle-btn']} variant="light"><span className="navbar-toggler-icon" /></Button> */}
				<Navbar.Collapse id="responsive-navbar-nav" className={clsx({ [classes['expand']]: !collapsed })}>
					<Nav className={clsx({
					[classes['panel']]: true,
					[classes['collapse']]: false,
					[classes['expand']]: true,
					})}>
						{
							navigationButtons.map(btn => <Nav.Link 
								href={btn.key.startsWith('/') ? `${PUBLIC_URL}${btn.key}` : '#'} 
								// active={btn.key === activeKey}
								onClick={(ev: any) => {
									ev.preventDefault();
									if (btn.onClick) {
										btn.onClick?.();
									} else {
										onClickNavigationButton?.(btn.key);
									}
									setExpanded(false);
								}}
							>
								<div
									className={clsx({
										[classes['caption']]: true,
										[classes['active']]: btn.key === activeKey,
										[tabBarIconExtraClasses?.[btn.key] ?? '']: tabBarIconExtraClasses && btn.key in tabBarIconExtraClasses
									})}>{btn.caption}</div>
							</Nav.Link>)
						}
						{ isSmallScreen && <Nav.Link>
								<div className={classes['contact-email']}>
									<i className="fas fa-envelope-open" />
									<div className={classes['email']}>{EMAIL}</div>
								</div>
							</Nav.Link>}
						{ isSmallScreen && <Nav.Link>
								<div className={classes['contact-phone']}>
									<i className="fas fa-phone-alt" />
									<div className={classes['phone']}>{TEL}</div>
								</div>
							</Nav.Link>}
					</Nav>
				</Navbar.Collapse>
			</div>
			{/* {
				collapse ? 
				<div>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav" className={classes['collapse']}>
					<Nav>
						{
							navigationButtons.map(btn => <Nav.Link 
								href={`#${btn.key}`} 
								// active={btn.key === activeKey}
								onClick={() => onClickNavigationButton(btn.key)}
							>
								<span 
									className={clsx({
										[classes['caption']]: true,
										[classes['active']]: btn.key === activeKey,
									})}>{btn.caption}</span>
							</Nav.Link>)
						}
					</Nav>
					</Navbar.Collapse>
				</div> :
				<Nav>
				{
					navigationButtons.map(btn => <Nav.Link 
						href={`#${btn.key}`} 
						// active={btn.key === activeKey}
						onClick={() => onClickNavigationButton(btn.key)}
					>
						<span 
							className={clsx({
								[classes['caption']]: true,
								[classes['active']]: btn.key === activeKey,
							})}>{btn.caption}</span></Nav.Link>)
				}
				</Nav>
			} */}
			{/* </Navbar.Collapse> */}
		</Navbar>
	</div>
}