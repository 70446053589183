import { LocaleStateLocal } from "reducers/locale";

const zhHK: LocaleStateLocal = {
  locale: "zh_HK",
  lang: {
    msgUserPwIncorrect: "用戶名或密碼錯誤",
    msgPwMaxFailAttempt: "登入錯誤次數過多，帳戶已鎖定",
    msgPwMaxFailAttemptReset: "帳戶已解除鎖定",
    msgNetworkError: "系統錯誤",
    msgLoggedOut: "使用期限己過，請重新登入",
    msgSignInAgain: "重新登入",
    msgPasswordExpired: "密碼已過期, 請修改密碼",
    msgDataNotFound: "找不到相關資料",
    msgPermissionDenied: "您無權訪問相關資料",
    msgVersionMismatched: "另一位操作者修改了當前資料, 請重新加載",
    msgDuplicatedBuilding: "大廈檔案已存在",
    msgAlreadyAssignedClient: "客戶已被認領",
    msgFloorUnitValidationError: "請輸入正確的格式，例：G,1-2,3-10,11",
    msgFloorLevelValidationError: "部分樓層沒有被低中高層涵蓋",
    msgFailedToSendEmailError: "發送失敗, 請重新嘗試。",
    msgFailedClientSignError: "簽署失敗, 請重新嘗試。",
    msgUnsupportedFileFormat: "不支援此文件格式: %s",
    msgBuildingAliasAlreadyExists: "大廈副地址已存在",
    msgFileSizeExceed: "此文件大小超過限制",
    msgConfirmDelete: "確認刪除?",
    msgConfirmDone: "確認已處理?",
    msgLoading: "載入中",
    msgPullDownToRefresh: "向下拉動以重載",
    msgReleaseToRefresh: "放開以重載",
    msgWelcome: "歡迎",
    msgMustBeNumber: "須為數字",
    msgMaxLength: "最多%s字",
    u10k: "萬",
    u1M: "百萬",
    u100M: "億",
    u1B: "十億",
    uFt: "呎",
    uCurr: "$",
    to: "至",
    infinity: "不限",

    dateModified: "最後更新",
    modifiedBy: "最後更新帳戶",
    dateCreated: "新增日期",
    createdBy: "創建帳戶",
    remarks: "備註",

    pageTitle: "陽光物業",

    memos: "備忘錄",
    content: "內容",
    author: "作者",
    actionAddMemo: "新增備忘",
    msgPleaseInputMemo: "請輸入備忘....",

    captionUploadedAt: "上載於",
    captionUnsaved: "尚未儲存",
    captionName: "姓名",
    captionEnglishName: "英文姓名",
    captionChineseName: "中文姓名",
    captionDeleted: "已刪除",
    captionAllDistricts: "全部地區",
    captionUnlimited: "不限",
    captionNextPage: "下一頁",
    captionPrevPage: "上一頁",

    actionUpload: "上載",
    actionConfirmAndUpload: "確認並上載",
    actionDownload: "下載",
    actionNextStep: "下一步",
    actionReset: "重設",
    actionConfirm: "確認",
    actionSave: "儲存",
    actionSkip: "略過",
    actionSubmit: "提交",
    actionAdd: "新增",
    actionDelete: "刪除",
    actionSaveForm: "儲存表格",
    actionGenerate: "生成PDF檔案",
    actionGenerateAndSign: "簽署",
    actionUserProfile: "帳戶設定",
    actionLogout: "登出",
    actionYes: "是",
    actionNo: "否",
    actionSearch: "搜尋",
    actionClearSorting: "清除排列",
    actionClose: "關閉",
    actionCancel: "取消",
    actionGoBack: "返回",
    actionContinue: "繼續",
    actionRemoveSelected: "刪除所選",
    actionManage: "管理",
    actionView: "檢視",
    actionMore: "更多",

    captionDistrict: "地區",
    captionHKI: "香港島",
    captionKLN: "九龍",
    captionNT: "新界",
    captionSearch: "搜尋...",
    captionSearchHistory: "搜尋紀錄",
    captionNoSearchResults: "沒有搜尋結果",

    // Quick Access Bar Lang
    navDashboard: "儀表板",
    navPropertyStockSearch: "樓盤搜尋",
    navTransaction: "成交紀錄",
    navClient: "客戶檔案",
    navBuilding: "大廈檔案",
    navMemoEnquiry: "備忘查閱",
    navSystemSetting: "系統管理",
    navCommissionReport: "銷售及分佣報告",
    navUserMgt: "帳戶管理",
    navBookmarks: "心水樓盤",
    navSavedSearchCriteria: "已儲存搜尋",
    navActivityAuditing: "使用記錄",
    navOptionEditor: "選項管理",
    navHome: "首頁",
    navProperties: "物業資料",
    navPropertiesForSale: "買盤",
    navPropertiesForLease: "租盤",
    navPropertiesIndustrial: "工商鋪",
    navAgent: "代理",
    navNotification: "通知",
    navEnquiry: "聯絡代理",
    navTopAgents: "星級代理",
    navAbout: "關於我們",
    navWebMap: "網站地圖",
    navMarketNews: "市場資訊",

    // notification
    titleNotifications: "通知",
    titleGotoNotificationCenter: "前往通知中心",
    captionIsRead: " (已讀)",
    captionExpired: "過期",
    actionDone: "完成",

    //language (SAME in all language file)
    traditionalChinese: "繁體中文",
    simplifiedChinese: "简体中文",
    english: "English",
    traditionalChineseAbbr: "繁",
    simplifiedChineseAbbr: "简",
    englishAbbr: "EN",

    captionPhoto: "相片",
    captionVideo: "影片",
    captionFloorPlan: "平面圖",

    actionPrevPhoto: "上一張",
    actionNextPhoto: "下一張",

    captionShare: "分享",
    captionFindHelpByClickingAvatar: "按頭像找代理幫手",
    captionContactOurTeam: "聯絡我們的專業代理團隊",
    msgUnsavedChanges: "未儲存本頁修改, 確定要離開 ?",
  },

  langAgent: {
    actionContactAgent: "聯絡代理",
    actionRating: "給評級",
    actionMoreComment: "更多評語",
    actionLoadMoreAgents: "載入更多",

    captionCustomerRating: "客戶評級",
    titleAgentComments: "客戶評語",
    titleAgentDetail: "代理詳情",
    titleOurAgents: "我們的代理",
    titleRatingAndCommentAgent: "評級代理",

    msgNoComment: "(暫無評語)",
    msgProvideInfo: "請提供以下資料，代理會很快與你聯絡。",
    msgMaxLength: "最多50字",
    msgSubmitCommentSuccess: "已經提交評語",
    msgNoRecord: "暫無紀錄",

    errPleaseRate: "請評級",
    errPleaseComment: "請評語",
    errPleaseEnterNoMoreThan50words: "請提交最多50字",

    captionComment: "評語",
    captionSubmitAsAnonymous: "以匿名身份提交",
    captionCustomer: "客戶",
    captionAnonymousCustomer: "匿名客戶",
  },
  langRegister: {
    title: "會員註冊",

    captionEmail: "電郵地址",
    captionPassword: "密碼",
    captionConfirmPassword: "確認密碼",
    captionPhoneNumber: "聯絡電話",
    captionName: "姓名",

    actionRegister: "註冊",
    actionLogin: "會員登入",

    msgRegistrationSuccess: "註冊成功",
    msgPasswordFailComplexityRequirement: "密碼必須符合設定要求",
    msgEmailAlreadyBeenRegistered:
      "您輸入的電郵已被註冊，若想重設現有用戶之密碼，請於登錄頁面使用忘記密碼功能",

    errEmail: "請輸入正確的電郵地址",
    errPassword: "請輸入密碼",
    errPasswordLeadingTailingSpace: "請移除頭尾空格",
    errConfirmPassword: "請確認密碼",
    errPasswordMismatch: "輸入的密碼不一致",

    //registration more info
    errPhoneNumber: "請輸入電話號碼",
    errInvalidPhoneNumber: "請輸入正確的電話號碼",

    //password complexity
    pwMinLength: "密碼長度最少為",
    pwAtLeast: "包含最少",
    pwUnit: "個",
    pwLowercaseChar: "小寫字母",
    pwUppercaseChar: "大寫字母",
    pwSpecialChar: "特殊符號",
    pwNumber: "數字",
  },
  langClientProfile: {
    captionEmail: "電郵地址",
    captionPhoneNumber: "聯絡電話",
    captionName: "姓名",
    captionSalutation: "稱呼",
    captionPreferenceSetting: "喜好設定",
    captionSearchDistrict: "地區訂閲",
    captionReceiveEmail: "推廣電郵",
    captionSubscribeNewsletter: "客戶通訊",
    captionOldPassword: "舊密碼",
    captionNewPassword: "密碼",
    captionConfirmPassword: "確認密碼",

    actionChangePassword: "更改密碼",
    actionUploadPhoto: "上載照片",

    errOldPassword: "請輸入現有密碼",
    errNewPassword: "請輸入新密碼",
    errPasswordLeadingTailingSpace: "請移除頭尾空格",
    errConfirmNewPassword: "請確認密碼",
    errPasswordMismatch: "輸入的密碼不一致",
    errPhoneNumber: "請輸入正確的電話號碼",

    msgSaveSuccess: "儲存成功",
    msgChangePasswordSuccess: "更改密碼成功",
    msgPasswordMismatched: "現有密碼不正確",
    msgPasswordFailComplexityRequirement: "新密碼必須符合設定要求",
    msgPreviousPasswordUsed: "新密碼不可與舊密碼相同",
    msgPleaseUploadFile: "請選擇檔案",
  },

  langHome: {
    actionBuy: "買樓",
    actionRent: "租樓",
    actionSell: "放盤",
    actionAdvanceSearch: "進階搜尋",
    actionMore: "更多",
    actionMoreProperties: "更多樓盤",
    actionMoreNews: "更多資訊",
    actionMoreAgents: "更多代理",
    actionSendMessage: "送出信息",

    captionSearchPlaceholder: "地區 / 大廈名稱 / 街道 / 樓盤編號",
    captionRange: "$範圍",
    captionRoomCount: "房間",
    captionName: "姓名",
    captionFirstName: "名",
    captionLastName: "姓",
    captionPhoneNumber: "聯絡電話",
    captionEmail: "電郵地址",
    captionDistrict: "地區",
    captionMessage: "信息",

    titleLatestPropertiesSell: "最新賣盤",
    titleLatestPropertiesLease: "最新租盤",
    titleNews: "市場動態",
    titleTopAgents: "星級代理",
    titleAgentDistrict: "代理地區",
    titleTopAgentComments: "客戶評價",
    titleGetInTouch: "聯繫我們",

    titleLatestNews: "最新消息",
    titleDownloadApp: "App 下載",
    titleCustomerService: "客戶服務",
    titleFollowUs: "聯絡我們",

    linkAboutUs: "關於我們",
    linkFAQ: "常見問題",
    linkPrivacyStatement: "私隱政策",
    linkTermsAndCondition: "條款細則",
    linkContactUs: "聯絡我們",
  },
  langDistrictSelectDialog: {
    captionDistrict: "地區",
    captionHKI: "香港島",
    captionKLN: "九龍",
    captionNT: "新界",

    actionOK: "OK",
    selectAll: "選擇全部",
  },

  langEnquiryDialog: {
    titleEnquiry: "查詢",
    titleEnquirySuccess: "聯絡代理",

    msgProvideInfo: "請提供以下資料，我們的代理會很快與你聯絡。",
    msgEnquirySuccess: "多謝你的資料，代理會很快與您聯絡",

    captionEmail: "電郵地址",
    captionContact: "聯絡電話",
    captionName: "姓名",
    captionDistrict: "地區",

    actionContactMe: "聯絡當區代理",
    actionContactMeLeadAgents: "聯絡物業代理",
    actionSameDistrictAgents: "指定代理",

    errContact: "請輸入正確的聯絡電話",
    errEmail: "請輸入正確的電郵地址",
    errName: "請輸入姓名",
    errDistrict: "請選擇地區",
    errAtLeastTelOrEmail: "請至少輸入電話或電郵",
  },
  langLogin: {
    actionLogin: "登入",
    actionLoginOrRegister: "登入/註冊",
    actionForgetPassword: "忘記密碼",
    actionMemberRegistration: "註冊成為會員",
    msgFacebookEmailRequired: "請提供您的電郵地址以完成註冊",
    msgInvalidEmail: "請輸入正確的電郵地址",
    msgSocialLoginCancelled: "第三方帳戶登入已取消",
    msgLoginSuccess: "登入成功!",
    msgEmailAlreadyBeenRegistered: "您的電郵已註冊，請使用您的密碼登入",
    msgForgetPasswordEmailRequired: "請提供您的電郵地址以完成處理",
    msgForgetPasswordRequestSuccess: "重設密碼的連結已發送到你的電子郵箱",
    msgResetPasswordTokenExpiry: "重設密碼請求已過時，請重新發出請求",
    msgPasswordFailComplexityRequirement: "密碼必須符合設定要求",
    msgResetPasswordSuccess: "重設密碼成功",
    msgPreviousPasswordUsed: "新密碼不可與舊密碼相同",
    msgAcceptTermsAndConditionsAndPolicy: "註冊即表示你同意我們的使用條款與私隱政策。",
    titleFacebookEmail: "Facebook 登入",
    titleMemberLogin: "會員登入",
    titleResetPassword: "重設密碼",

    actionLoginWithFacebook: "使用 Facebook 帳戶登入",
    actionLoginWithGoogle: "使用 Google 帳戶登入",
    actionLoginWithApple: "使用 Apple ID 登入",

    captionEmail: "電郵地址",
    captionPassword: "密碼",
    captionRePassword: "重新輸入密碼",

    errEmail: "請輸入正確的電郵地址",
    errInvalidEmail: "電郵地址錯誤",
    errInvalidToken: "認證失敗",
    errPassword: "請輸入密碼",
    errPasswordLeadingTailingSpace: "請移除頭尾空格",
    errPasswordMismatch: "請輸入和上面一致的密碼",

    plsRegisterOrLogin: "請登入或免費註冊，以使用更多服務。",
  },
  langNews: {
    titleNews: "市場動態",
    // Sharing
    msgSharingTemplate: "我喺陽光物業顧問搵到個樓盤新聞：%s，一齊來望下~",
    msgNoRecord: "暫無紀錄",
  },
  langNotification: {
    titleNotifications: "訊息",
    actionYes: "好",
    actionNoNeed: "不用了",

    msgNoRecord: "暫無訊息",
    actionProcessed: "已處理",
  },
  langProperty: {
    actionReset: "重設",
    actionSearch: "搜尋",

    actionBuy: "買盤",
    actionRent: "租盤",

    grossAbbr: "建",
    netAbbr: "實",
    rent: "租",
    sale: "售",

    captionUnpublished: "已下架",
    metaAppUnpublishedLabelFontSize: "0.8rem",

    captionPropertyNo: "物業編號",
    captionDateModified: "更新日期",
    captionKeyword: "關鍵字",

    captionPrice: "價錢",
    captionRent: "租金",
    captionDistrict: "地區",
    captionPropertyUsage: "物業類型",
    captionPricePerSize: "呎價",
    captionArea: "面積",
    captionAddress: "地址",
    captionMoreOption: "更多選項",
    captionAdvancedSearch: "進階搜尋",
    captionBuilding: "屋苑/大廈名稱",
    captionStreet: "街道",
    captionRooms: "房",
    captionRoom: "房間",
    captionSuite: "套房",
    captionSuites: "套房",
    captionOpenType: "開放式",
    captionBathroom: "浴室/廁所",
    captionBalcony: "露台",
    captionGarden: "花園",
    captionHelperRoom: "工人房",
    captionRooftop: "天台",
    captionOccupancyPermitAge: "入伙期(年)",
    captionHasHelperRoom: "有",
    captionNoHelperRoom: "無",
    captionFacing: "座向",
    captionDeco: "裝修",
    captionView: "景觀",
    captionOtherFeatures: "特色",
    captionClubHouseFacilities: "會所設施",
    captionOthers: "其它",
    captionPrimarySchoolNet: "小學校網",
    captionSecondarySchoolNet: "中學校網",
    captionPrimarySchool: "小學",
    captionSecondarySchool: "中學",
    captionSetAsDefaultSearchCriteria: "設定為預設搜尋條件",
    captionFullTransactions: "所有成交",
    captionTransactionDate: "成交日期",

    captionPerGross: "建呎",
    captionPerNet: "實呎",
    captionYears: "年",
    captionPricePerFeet: "平均實用尺價 ($)",

    // Sorting
    captionSorting: "排序",
    captionDefaultSorting: "刊登日期",
    captionPriceRentSorting: "定價",
    captionRentSorting: "定價",
    captionAreaSorting: "面積",
    captionPriceRentPerAreaSorting: "呎價",
    captionRentPerAreaSorting: "呎租",

    captionHotpick: "精選",

    titlePropertyInfo: "物業資訊",
    titlePropertyFeatures: "物業特點",
    titlePropertyAgents: "物業代理",
    titleRecentTransactions: "近期成交",
    titlePropertySameDistrict: "同區其他樓盤",
    titlePropertySameBuilding: "同建築物其他樓盤",
    titleBookmarkList: "心水樓盤",
    titleTenYearsPricePerFeet: "十年平均實用尺價",
    captionFeaturesOrFacilities: "特色/設施",
    captionOtherInfo: "其他資訊",
    captionSchoolNet: "校網",
    captionMorePropertyInfo: "更多物業相關資訊",
    captionOtherPropertiesForSameBuilding: "同建築物其他樓盤",
    captionOtherPropertiesForSameDistrict: "同區其他樓盤",

    // Sharing
    msgSharingTemplate: "我喺陽光物業顧問搵到個筍盤：%s，一齊來望下~",

    // Sold Leased
    msgSoldClicked: "此樓盤已經出售, 請瀏覽其他樓盤",
    msgLeasedClicked: "此樓盤已經出租, 請瀏覽其他樓盤",

    // Save search
    titleSaveSearch: "儲存選項",
    captionLatestSaveSearchShown: "只會顯示最近的%s個儲存選項",
    hintForDelSaveSearch: "向左滑動以刪除",
    titleCriteriaTitle: "選項標題",
    msgPleaseEnterTitle: "請輸入標題",
    msgSaveSearchSuccess: "已成功儲存選項",
    msgNotExists: "該樓盤不存在或已下架",
    errPleaseEnterTitle: "請輸入標題",
    captionTitle: "標題",

    actionLoadMore: "載入更多",
    actionCenterMap: "地圖置中",

    msgNoRecord: "暫無紀錄",
    titleNoPropertyFound: "找不到您所需的樓盤",
    msgNoPropertyFound:
      "請嘗試更改搜尋的條件和地圖區域。您亦可直接聯絡我們的代理",

    titleDisplayMap: "地圖",
    titleDisplayList: "列表",
  },
  langSetting: {
    titleSettings: "設定",
    captionAbout: "關於",
    titleAboutCompany: "關於我們",
    captionCompanyMission: "公司宗旨",
    captionContactUs: "聯絡我們",
    captionLanguage: "語言",
    captionMessages: "訊息",
    captionPushNotiEnabled: "推送服務",
    captionTermsAndConditions: "使用條款",
    captionPrivacy: "私隱政策",
    captionQA: "常見問題",
    captionVersion: "版本",
  },
};

export default zhHK;