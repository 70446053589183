import { GOOGLE_MAP_API_KEY } from 'api';
import useLocale from 'common/hooks/useLocale';
import { registerTouchableClassNames } from 'common/polyfills/touch-polyfill';
import { isNonEmpty } from 'common/utils/utils';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';
import Supercluster from 'supercluster';
import useSuperCluster from 'use-supercluster';
import classes from './PropertyMapView.module.scss';

// Default values in case not defined in backend settings //
const KLN_CENTER = { lat: 22.346967, lng: 114.179379 };
const NT_CENTER = { lat: 22.458957, lng: 114.092938 };
const HKI_CENTER = { lat: 22.264033, lng: 114.193466 };

registerTouchableClassNames(
  classes['cluster-marker'],
  classes['region-marker'],
);

export interface PropertyMapItem {
  id: number;
  lat: number;
  lng: number;
  district: string;
}

/* trial */
export interface PropertyMapCluster extends PropertyMapItem {
  title?: string;
  point_count?: number;
  cluster?: boolean;
  cluster_id?: number;
  point_count_abbreviated?: string | number;
}

export interface PropertyMapViewProps {
  properties: PropertyMapItem[];
  onClusterClick?: (pidList: number[]) => any;
  center: { lat: number, lng: number };
  actualViewHeightPct: number;
  isApp?: boolean;
  settings?: {
    regionAggregationZoom?: number;
    klnCenter?: { lat: number, lng: number };
    ntCenter?: { lat: number, lng: number };
    hkiCenter?: { lat: number, lng: number };
  };
  onClusteredPidListChange?: (pidList: number[]) => any;
}

interface MarkerProps {
  key?: string | number;
  lat: number;
  lng: number;
  children: any;
}

const Marker = (props: MarkerProps) => props.children;

// interface PopupProps {
//   content?: string;
// }

// const Popup = (props: PopupProps) =>  {
//   const { content } = props;
//   return (
//     <div className={classes['info-modal']}>
//       <p>{content}</p>
//     </div>
//   );
// }

const centerWithOffset = (center: { lat: number, lng: number },
  // offsetHeightPx: number,
  // viewHeightPx: number,
  actualViewHeightPct: number,
  bounds: [number, number, number, number]
) => {
  const boundLengthY = bounds[3] - bounds[1];
  const actualViewBoundLengthY = boundLengthY * (actualViewHeightPct);

  // console.log(center);
  // console.log('boundLengthY', boundLengthY, 'actualViewBoundLengthY', actualViewBoundLengthY);
  // console.log({ lat: center.lat - actualViewBoundLengthY / 2, lng: center.lng });
  // const originalCenterY = bounds[3] - center.lat;
  // const offsetCenterY = originalCenterY * ((offsetHeightPx) / viewHeightPx);
  return { lat: center.lat - (boundLengthY - actualViewBoundLengthY) / 2, lng: center.lng };
};

export default function PropertyMapView(props: PropertyMapViewProps) {
  const { properties, center, isApp, actualViewHeightPct, settings, onClusterClick, onClusteredPidListChange } = props;

  const [suppressOnClusterChange, setSuppressOnClusterChange] = useState(false);

  /** Google Map Object */
  const mapRef = useRef<google.maps.Map | null>(null);

  /** Center State */
  const [managedCenter, setManagedCenter] = useState(center);

  /** Points transformed from property map items */
  const points: Array<Supercluster.PointFeature<PropertyMapCluster>> = properties?.map(property => (
    {
      type: "Feature",
      properties: property,
      geometry: { type: 'Point', coordinates: [property.lng, property.lat] }
    }
  ));

  const districtSelected = useMemo(() => {
    const districts = [...new Set(properties.map(p => p.district))];
    return districts.sort().join(',');
  }, [properties]);

  const [apiReady, setApiReady] = useState(false);

  // On property list changed (by searching) in desktop, recalculate the center //
  useLayoutEffect(() => {
    if (!properties || properties.length === 0 || !apiReady || isApp) {
      return;
    }
    const newBounds = new google.maps.LatLngBounds();
    properties.forEach(property => {
      newBounds.extend({ lat: property.lat, lng: property.lng });
    });

    mapRef.current?.fitBounds(newBounds);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtSelected, apiReady, properties]);

  /** Current bounds */
  const [bounds, setBounds] = useState<[number, number, number, number]>([0, 0, 0, 0]);

  /** Current zoom */
  const [zoom, setZoom] = useState(10);

  /** @deprecated */
  // const [seen, setSeen] = useState(false);
  // const [clickedId, setClickedId] = useState<number>();
  // const [clickedClusterId, setClickedClusterId] = useState<number>();

  // Settings //
  const { locale, lang } = useLocale();
  const regionAggregationZoom = settings?.regionAggregationZoom ?? 11;
  const klnCenter = settings?.klnCenter ?? KLN_CENTER;
  const hkiCenter = settings?.hkiCenter ?? HKI_CENTER;
  const ntCenter = settings?.ntCenter ?? NT_CENTER;
  const districtHkiOptions = useSelector((state: IRootState) => state.locale.districtHkiOptions);
  const districtKltOptions = useSelector((state: IRootState) => state.locale.districtKltOptions);
  const districtNtOptions = useSelector((state: IRootState) => state.locale.districtNtOptions);
  const regionBundles: RegionBundles = {
    districtNtOptions, districtHkiOptions, districtKltOptions,
  };
  /** Region Aggregate */
  const { lenHki, lenNt, lenKln } = useMemo(() => aggregateRegion(properties, regionBundles), [properties]);

  /** Clusters */
  const { clusters, supercluster } = useSuperCluster({
    points,
    bounds,
    zoom,
    options: { radius: 40, maxZoom: 23, minZoom: 0 }
  });

  const setToDistrictLevelZoom = useMemo(() => (centerPoint: { lat: number, lng: number }) => {
    mapRef.current?.setCenter(centerPoint);
    mapRef.current?.setZoom(regionAggregationZoom + 1);
  }, [mapRef]);

  useEffect(() => {
    if (suppressOnClusterChange) {
      setSuppressOnClusterChange(false);
      return;
    }
    onClusteredPidListChange?.(
      clusters
        .map(c => c.properties.id ? [c.properties.id] : (supercluster?.getLeaves(Number(c.id), Infinity).map(p => p.properties.id) ?? []))
        .reduce((a, b) => [...a, ...b], [])
        .concat([-1])
    );
    // console.log('cluster changed', clusters, clusters.map(c => c.properties.id));
  }, [clusters]);
  // console.log("print superCluster:", supercluster);

  // console.log("print seen:", seen);

  /** Root element */
  const rootRef = useRef<HTMLDivElement>(null);

  return <div ref={rootRef} style={{ height: '100%', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{
        key: GOOGLE_MAP_API_KEY,
        language: locale,
      }}
      yesIWantToUseGoogleMapApiInternals
      center={managedCenter}
      zoom={10}
      // distanceToMouse={() => {}}
      onGoogleApiLoaded={({ map }) => {
        mapRef.current = map;
        setApiReady(true);
      }}
      onChange={({ zoom, bounds }) => {
        setZoom(zoom);
        setBounds([
          bounds.nw.lng,
          bounds.se.lat,
          bounds.se.lng,
          bounds.nw.lat
        ]);
      }}
      options={{
        fullscreenControl: false,
        maxZoom: 19,
        minZoom: 10,
        clickableIcons: false,
      }}
    >
      {(() => { // IIFE()
        if (zoom <= regionAggregationZoom) {
          return [
            <Marker key={'kln'} lat={klnCenter.lat} lng={klnCenter.lng}>
              <div className={classes['region-marker']} onClick={() => setToDistrictLevelZoom(klnCenter)}>
                <div className={classes['title']}>{lang.captionKLN}</div>
                <div className={classes['count']}>{lenKln}</div>
              </div>
            </Marker>,

            <Marker key={'nt'} lat={ntCenter.lat} lng={ntCenter.lng}>
              <div className={classes['region-marker']} onClick={() => setToDistrictLevelZoom(ntCenter)}>
                <div className={classes['title']}>{lang.captionNT}</div>
                <div className={classes['count']}>{lenNt}</div>
              </div>
            </Marker>,

            <Marker key={'hki'} lat={hkiCenter.lat} lng={hkiCenter.lng}>
              <div className={classes['region-marker']} onClick={() => setToDistrictLevelZoom(hkiCenter)}>
                <div className={classes['title']}>{lang.captionHKI}</div>
                <div className={classes['count']}>{lenHki}</div>
              </div>
            </Marker>
          ];
        }

        return clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const pointCount = cluster.properties.point_count ?? 0;
          const isCluster = cluster.properties.cluster ?? true;

          if (isCluster) {
            let title = '';
            if (isNonEmpty(cluster.properties.cluster_id)) {
              // console.log("print supercluster leaves", supercluster?.getLeaves(Number(cluster.id)));
              // console.log("print supercluster children", supercluster?.getChildren(Number(cluster.id)));
              title = supercluster?.getLeaves(Number(cluster.id))?.map(sc => sc.properties.title?.concat(`(${sc.properties.id})`))?.join(",") ?? '';
            } else {
              title = cluster.properties.title;
            }
            return <Marker
              key={cluster.id}
              lat={latitude}
              lng={longitude}
            >
              <div
                className={classes['cluster-marker']}
                // style={{
                //   width: `${10 + (pointCount / points.length) * 40}px`,
                //   height: `${10 + (pointCount / points.length) * 40}px`
                // }}
                onClick={() => {
                  setSuppressOnClusterChange(true);
                  const temp = supercluster?.getClusterExpansionZoom(Number(cluster.id)) ?? 0;
                  var expansionZoom = mapRef.current?.getZoom();
                  if (temp >= 0) {
                    expansionZoom = Math.min(
                      supercluster?.getClusterExpansionZoom(Number(cluster.id)) ?? 20,
                      20
                    );
                  } else {
                    expansionZoom = 18;
                    //setFlag_showInfo(true);

                  }
                  // console.log("lat: " + latitude + " lng: " + longitude);
                  // console.log("zoom lv: " + expansionZoom);
                  // mapRef?.current?.setZoom(expansionZoom);
                  // mapRef?.current?.panTo({ lat: latitude, lng: longitude });

                  if (isNonEmpty(cluster.properties.id)) {
                    // setClickedId(cluster.properties.id);
                    // setClickedClusterId(undefined);
                    onClusterClick?.([cluster.properties.id]);
                  } else if (isNonEmpty(cluster.id)) {
                    // setClickedClusterId(Number(cluster.id));
                    // setClickedId(undefined);
                    onClusterClick?.(supercluster?.getLeaves(Number(cluster.id), Infinity)?.map(l => l.properties.id) ?? []);
                  }

                  setManagedCenter(centerWithOffset({
                    lat: cluster.geometry.coordinates[1],
                    lng: cluster.geometry.coordinates[0],
                  }, actualViewHeightPct, bounds));
                  // setSeen(!seen); // display debug info
                }

                }
              >
                {pointCount !== 0 ? pointCount : 1}
              </div>
              <div>
                {/* { seen && 
              ((isNonEmpty(cluster.properties.id) && clickedId === cluster.properties.id || 
                isNonEmpty(cluster.id) && clickedClusterId === cluster.id))?
              // <div className={classes['info-modal']}>
              //   <p>{cluster.properties.title}</p>
              // </div>
              <Popup content={title}/>
              // <div style={ { width: '50px', height: '50px', backgroundColor: '#ffffff'}}>
              //   {cluster.properties.title}
              // </div>
            : null
            } */}
              </div>
            </Marker>
          }
        })
      })()}
    </GoogleMapReact>
  </div>
}

function aggregateRegion(properties: PropertyMapItem[], regionBundles: RegionBundles) {
  let lenKln = 0, lenNt = 0, lenHki = 0;
  properties.forEach(property => {
    const district = property.district;
    if (regionBundles.districtHkiOptions[district]) {
      lenHki++;
    } else if (regionBundles.districtKltOptions[district]) {
      lenKln++;
    } else if (regionBundles.districtNtOptions[district]) {
      lenNt++;
    }
  });

  return { lenKln, lenNt, lenHki };
}