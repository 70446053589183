import { ApiError, ApiPageResult, ApiResult } from 'api';
import { agentApi } from 'api/agentApi';
import CAAgentDTO from 'common/dto/CAAgentDTO';
import CAAgentRatingCommentDTO from "common/dto/CAAgentRatingCommentDTO";
import CAClientContactDTO from "common/dto/CAClientContactDTO";
import { selectLocale } from 'common/hooks/useLocale';
import { IRootState } from 'reducers';
import { agentContactActions, AgentContactState } from 'reducers/agent-contact';
import { agentDetailActions } from 'reducers/agent-detail';
import { agentListActions, AgentListState } from "reducers/agent-list";
import { agentRatingCommentActions } from 'reducers/agent-rating-comment';
import { alertConfirmDialogActions } from 'reducers/alert-confirm-dialog';
import { enquiryDialogActions, EnquiryDialogState } from 'reducers/enquiry-dialog';
import { getInTouchActions, GetInTouchState } from 'reducers/get-in-touch';
import { HomeState } from 'reducers/home';
import { layoutActions } from 'reducers/layout';
import { PropertyStockDetailState } from 'reducers/property-detail';
import { sellerEnquiryFormActions, SellerEnquiryState } from 'reducers/seller-enquiry';
import { call, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { PASSagaContext } from 'sagas';
import { ActionType, getType } from "typesafe-actions";
import { apiTaskWrapper } from './saga-commons';

export function* watchAgentListFetchRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(agentListActions.doFetchAgents),
    apiTaskWrapper(fetchAgents),
    context,
  );
}

export function* fetchAgents(_context: PASSagaContext, action: ActionType<typeof agentListActions['doFetchAgents']>) {
  const { isSwitchingPage } = action.payload;
  // const { page }: AgentListState['criteria'] = yield select((state: IRootState) => state.agentList.criteria);

  const criteria: AgentListState['criteria'] = yield select((state: IRootState) => state.agentList.criteria);
  const { token } = yield select((state: IRootState) => state.login);

  const { data, error }: ApiPageResult<CAAgentDTO> = yield call(agentApi.getAgents,
    { ...criteria, page: isSwitchingPage ? criteria.page + 1 : 0 },
    token
  );

  if (error) {
    throw ApiError.of(error!);
  }

  const agents = data!.content;
  
  if (isSwitchingPage) {
    yield put(agentListActions.updateCriteria({ page: criteria.page + 1 }));
    yield put(agentListActions.appendNextPage({ agents, hasMore: data!.currentPage+1 < data!.totalPages }));
  } else {
    yield put(agentListActions.updateCriteria({ page: 0 }));
    yield put(agentListActions.updateAgents({ agents, hasMore: data!.currentPage+1 < data!.totalPages }));
  }
}

export function* watchDoEnquiryRequested(context: PASSagaContext) {
  yield takeLatest(
    getType(enquiryDialogActions.doEnquiry),
    apiTaskWrapper(doEnquiry),
    context,
  );
}

export function* doEnquiry(_context: PASSagaContext, _action: ActionType<typeof enquiryDialogActions['doEnquiry']>) {
  const { contents, entry }: EnquiryDialogState = yield select((state: IRootState) => state.enquiryDialog);
  const { searchType }: HomeState = yield select((state: IRootState) => state.home);
  const { token } = yield select((state: IRootState) => state.login);
  
  const { lang, langEnquiryDialog } = yield select(selectLocale());
  const { error } = yield call(agentApi.contactAgent, {
    ...contents as any, entry,
    clientType: searchType,
  }, token);

  if (!error) {
    // Popup success message dialog.
    yield put(alertConfirmDialogActions.alert(langEnquiryDialog.titleEnquirySuccess, langEnquiryDialog.msgEnquirySuccess, lang.actionConfirm, ''));
    yield put(enquiryDialogActions.reset());
  } else {
    throw ApiError.of(error);
  }
}

export function* watchContactAgentRequested(context: PASSagaContext) {
  yield takeLatest(
    getType(agentContactActions.doContact),
    apiTaskWrapper(contactAgent),
    context,
  );
}

function* contactAgent(_context: PASSagaContext, _action: ActionType<typeof agentContactActions['doContact']>) {
  const { contents, currentAgent }: AgentContactState = yield select((state: IRootState) => state.agentContact);
  const { criteria: agentListCriteria }: AgentListState = yield select((state: IRootState) => state.agentList);
  const { currentPropertyStock }: PropertyStockDetailState = yield select((state: IRootState) => state.propertyDetail);
  // const { contents: propertyListCriteria }: PropertyListState = yield select((state: IRootState) => state.agentList);
  const { searchType }: HomeState = yield select((state: IRootState) => state.home);
  const { locale, lang, langEnquiryDialog, districtHkiOptions, districtKltOptions, districtNtOptions } = yield select(selectLocale());
  const { token } = yield select((state: IRootState) => state.login);

  const allDistrictOptions = { ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions };
  const { error } = yield call(agentApi.contactAgent, {
    englishName: locale === 'en' ? contents.name! : '',
    chineseName: locale !== 'en' ? contents.name! : '',
    tel: contents.contact!,
    email: contents.email!,
    entry: currentAgent.entry,
    districts: currentAgent.entry === 'D' ? (agentListCriteria.districts.length === 0 ? Object.keys(allDistrictOptions) : agentListCriteria.districts) : [currentPropertyStock.district],
    agentUserId: currentAgent.id,
    caPropertyStockId: contents.caPropertyStockId,
    clientType: searchType,
  }, token);

  if (!error) {
    // Popup success message dialog.
    yield put(alertConfirmDialogActions.alert(langEnquiryDialog.titleEnquirySuccess, langEnquiryDialog.msgEnquirySuccess, lang.actionConfirm, ''));
    yield put(agentContactActions.reset());
  } else {
    throw ApiError.of(error);
  }
}

export function* watchSellerEnquiryRequested(context: PASSagaContext) {
  yield takeLatest(
    getType(sellerEnquiryFormActions.doEnquiry),
    apiTaskWrapper(sellerEnquiry),
    context,
  );
}

function* sellerEnquiry(_context: PASSagaContext, _action: ActionType<typeof sellerEnquiryFormActions['doEnquiry']>) {
  const { contents }: SellerEnquiryState = yield select((state: IRootState) => state.sellerEnquiry);
  const { locale, lang, langEnquiryDialog } = yield select(selectLocale());
  const { token } = yield select((state: IRootState) => state.login);

  const { error } = yield call(agentApi.contactAgent, {
    englishName: locale === 'en' ? contents.name! : '',
    chineseName: locale !== 'en' ? contents.name! : '',
    tel: contents.contact!,
    email: contents.email!,
    entry: 'D',
    districts: contents.districts ?? [],
    clientType: 'SELLER_ENQUIRY',
  }, token);

  if (!error) {
    // Popup success message dialog.
    yield put(alertConfirmDialogActions.alert(langEnquiryDialog.titleEnquirySuccess, langEnquiryDialog.msgEnquirySuccess, lang.actionConfirm, ''));
    yield put(sellerEnquiryFormActions.reset());
  } else {
    throw ApiError.of(error);
  }
}

export function* watchGetInTouchRequested(context: PASSagaContext) {
  yield takeLatest(
    getType(getInTouchActions.doRequest),
    apiTaskWrapper(getInTouch),
    context,
  );
}

function* getInTouch(_context: PASSagaContext, _action: ActionType<typeof getInTouchActions['doRequest']>) {
  const { contents }: GetInTouchState = yield select((state: IRootState) => state.getInTouch);

  const { locale, lang, langEnquiryDialog } = yield select(selectLocale());
  
  const { error } = yield call(agentApi.getInTouch, {
    englishName: locale === 'en' ? contents.name! : '',
    chineseName: locale !== 'en' ? contents.name! : '',
    tel: contents.phone!,
    email: contents.email!,
    districts: contents.districts,
    message: contents.message?.replace(/\n/g,'<br/>').replace(/\r/g,'<br/>'),
  } as CAClientContactDTO);
  if (!error) {
    // Popup success message dialog.
    yield put(alertConfirmDialogActions.alert(langEnquiryDialog.titleEnquirySuccess, langEnquiryDialog.msgEnquirySuccess, lang.actionConfirm, ''));
    yield put(getInTouchActions.reset());
  } else {
    throw ApiError.of(error);
  }
}

export function* watchRatingCommentsFetchRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(agentDetailActions.doFetchComments),
    apiTaskWrapper(fetchComments),
    context,
  );
}

export function* fetchComments(_context: PASSagaContext, action: ActionType<typeof agentDetailActions['doFetchComments']>) {
  const { uid  } = action.payload;
  const { token } = yield select((state: IRootState) => state.login);

  const { data, error }: ApiResult<CAAgentRatingCommentDTO[]> = yield call(agentApi.getRatingComments, uid, token);

  if (error) {
    throw ApiError.of(error!);
  } else {
    yield put(agentDetailActions.updateComments({ comments: data ?? [], hasMore: false }));
  }
}

export function* watchAddRatingCommentFetchRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(agentRatingCommentActions.addRatingComment),
    apiTaskWrapper(addRatingComment),
    context,
  );
}

export function* addRatingComment(_context: PASSagaContext, action: ActionType<typeof agentRatingCommentActions['addRatingComment']>) {
  const { uid, ratingComment } = action.payload;
  const { token } = yield select((state: IRootState) => state.login);
  const { langAgent } = yield select((state: IRootState) => state.locale)

  const { data, error }: ApiResult<boolean> = yield call(agentApi.addRatingComment, uid, ratingComment as CAAgentRatingCommentDTO, token);

  if (error) {
    throw ApiError.of(error!);
  } else {
    yield put(agentRatingCommentActions.closeDialog());
    yield put(agentRatingCommentActions.reset());
    yield put(layoutActions.alert(langAgent.msgSubmitCommentSuccess, 'success'));
  }
}

export default [ watchAgentListFetchRequested, watchDoEnquiryRequested,
  watchContactAgentRequested, watchSellerEnquiryRequested,
  watchGetInTouchRequested,
  watchRatingCommentsFetchRequested,
  watchAddRatingCommentFetchRequested
];