import { BASE_URL } from 'api';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import { jumpers, useJump } from 'common/theme/jumper';
import { isNonEmpty, multiLang, priceToView } from 'common/utils/utils';
import InlinePropertyList from 'components/property/detail/InlinePropertyList';
import { PropertyResultListItemProps } from 'components/property/search/PropertyResultListItem';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { IRootState } from 'reducers';
import { inlinePropertyListActions } from 'reducers/inline-property-list';
import { loginSelectors } from 'reducers/login';
import { propertyStockDetailActions } from 'reducers/property-detail';

export default function InlinePropertyListPage() {
  const layoutProps = useLayoutProps();
  const history = useHistory();
  const { propertyNo, type, district } = useParams<any>();
  const dispatch = useDispatch();
  const { properties, contents: searchForm } = useSelector((state: IRootState) => state.inlinePropertyList);
  const { currentPropertyStock } = useSelector((state: IRootState) => state.propertyDetail);
  const { locale, districtHkiOptions, districtKltOptions, districtNtOptions, levelOptions, langProperty } = useLocale();
  const districtOptions = { ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions };
  const SAME_DISTRICT_PROPERTIES_PRICE_RANGE_PERCENTAGE = useSelector((state: IRootState) => state.systemSettings.ClientApp.PROPERTY_STOCK.SAME_DISTRICT_PROPERTIES_PRICE_RANGE_PERCENTAGE);
  const SAME_DISTRICT_PROPERTIES_RENT_RANGE_PERCENTAGE = useSelector((state: IRootState) => state.systemSettings.ClientApp.PROPERTY_STOCK.SAME_DISTRICT_PROPERTIES_RENT_RANGE_PERCENTAGE);
  const { searchType } = useSelector((state: IRootState) => state.home);

  const jump = useJump();
  const loggedIn = useSelector(loginSelectors.loggedIn());

  useEffect(() => {
    if (currentPropertyStock.propertyNo !== propertyNo) {
      dispatch(propertyStockDetailActions.doFetch({ propertyNo: propertyNo }));
    }
  }, []);

  useEffect(() => {
    dispatch(inlinePropertyListActions.reset());
    switch (type) {
      case 'district':
        if (isNonEmpty(currentPropertyStock.price) && isNonEmpty(SAME_DISTRICT_PROPERTIES_PRICE_RANGE_PERCENTAGE)) {
          dispatch(inlinePropertyListActions.edit('priceDisplay', [
            priceToView(currentPropertyStock.price - parseInt(SAME_DISTRICT_PROPERTIES_PRICE_RANGE_PERCENTAGE) * currentPropertyStock.price / 100, locale),
            priceToView(currentPropertyStock.price + parseInt(SAME_DISTRICT_PROPERTIES_PRICE_RANGE_PERCENTAGE) * currentPropertyStock.price / 100, locale)
          ]));
        } else {
          dispatch(inlinePropertyListActions.edit('priceDisplay', [-1, -1]));

        }
        if (isNonEmpty(currentPropertyStock.rent) && isNonEmpty(SAME_DISTRICT_PROPERTIES_RENT_RANGE_PERCENTAGE)) {
          dispatch(inlinePropertyListActions.edit('rent', [
            currentPropertyStock.rent - parseInt(SAME_DISTRICT_PROPERTIES_RENT_RANGE_PERCENTAGE) * currentPropertyStock.rent / 100,
            currentPropertyStock.rent + parseInt(SAME_DISTRICT_PROPERTIES_RENT_RANGE_PERCENTAGE) * currentPropertyStock.rent / 100
          ]))
        }
        else {
          dispatch(inlinePropertyListActions.edit('rent', [-1, -1]))

        }
        dispatch(inlinePropertyListActions.edit('status', ['SALES', 'RENT']));
        dispatch(inlinePropertyListActions.edit('district', [district]));
        break;
      case 'building':
        dispatch(inlinePropertyListActions.edit('sameBuildingOfPropertyStockId', currentPropertyStock.id));
        break;
    }
    dispatch(inlinePropertyListActions.fetch({ isSwitchingPage: false }));
  }, [dispatch, propertyNo, type, district, currentPropertyStock, SAME_DISTRICT_PROPERTIES_PRICE_RANGE_PERCENTAGE, SAME_DISTRICT_PROPERTIES_RENT_RANGE_PERCENTAGE]);

  const propertiesProps: PropertyResultListItemProps[] = properties.filter(property => property.propertyNo !== propertyNo).map(property => ({
    id: property.caPropertyStockId,
    label: `${multiLang(locale, property.streetZh, property.streetEn, property.streetSc) ?? ''} ${multiLang(locale, property.buildingNameZh, property.buildingNameEn, property.buildingNameSc) ?? ''} ${levelOptions[property.level] ?? ''}`, // ?
    street: multiLang(locale, property.streetZh, property.streetEn, property.streetSc) ?? '',
    buildingName: multiLang(locale, property.buildingNameZh, property.buildingNameEn, property.buildingNameSc) ?? '',
    level: levelOptions[property.level] ?? '',
    image: property.photoFilename ? `${BASE_URL}/files/${property.photoFilename}` : '', // property.image, 
    net: property.net,
    gross: property.gross,
    district: districtOptions[property.district], //
    price: property.price,
    rent: property.rent,

    bookmarked: property.isBookmarked,
    isHotpick: false,

    onBookmarkClick: () => {
      if (!loggedIn) {
        jump(jumpers.toLogin());
        return;
      }
      property.isBookmarked ?
        dispatch(inlinePropertyListActions.deleteBookmark({ caPropertyStockId: property.caPropertyStockId })) :
        dispatch(inlinePropertyListActions.addBookmark({ caPropertyStockId: property.caPropertyStockId }))
        ;
    },

    onClick: () => {
      history.push(`/properties/${property.propertyNo}`);
    },
  }));

  return <InlinePropertyList
    pageTitle={type === 'district' ? langProperty.titlePropertySameDistrict : langProperty.titlePropertySameBuilding}
    layoutProps={layoutProps}
    properties={propertiesProps}
    sortKey={searchForm.sortKey ?? null}
    direction={searchForm.direction ?? 'DESC'}

    onSortSelect={(newSort, newDirection) => {
      dispatch(inlinePropertyListActions.edit('sortKey', newSort));
      dispatch(inlinePropertyListActions.edit('direction', newDirection));
      dispatch(inlinePropertyListActions.fetch({ isSwitchingPage: false }));
    }}
    onBackClick={() => history.goBack()}
    onRequestNextPage={() => dispatch(inlinePropertyListActions.fetch({ isSwitchingPage: true }))}
    searchType={searchType}
  />
}