import { ApiError, ApiPageResult, ApiResult } from 'api';
import { customerApi } from 'api/customerApi';
import { propertyApi } from "api/propertyApi";
import CACustomerSavedSearchDTO, { CACustomerSavedSearchFeatureDTO } from "common/dto/CACustomerSavedSearchDTO";
import CAPropertyStockDetailDTO, { TransactionDTO } from 'common/dto/CAPropertyStockDetailDTO';
import CAPropertyStockListItemDTO from "common/dto/CAPropertyStockListItemDTO";
import CAPropertyStockMapItemDTO from 'common/dto/CAPropertyStockMapItemDTO';
import CAPropertyStockSearchDTO from "common/dto/CAPropertyStockSearchDTO";
import { jumpers, sagaJump } from 'common/theme/jumper';
import { isNonEmpty, priceFromView, priceToView, trackSearchGoal } from "common/utils/utils";
import { IRootState } from 'reducers';
import { bookmarkListActions } from 'reducers/bookmark-list';
import { fullTransactionListActions } from 'reducers/full-transaction-list';
import { inlinePropertyListActions } from 'reducers/inline-property-list';
import { propertyStockDetailActions } from 'reducers/property-detail';
import { propertyListActions, PropertyStockSearchForm } from "reducers/property-list";
import { propertyMapActions } from 'reducers/property-map';
import { saveSearchActions } from 'reducers/save-search';
import { call, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { PASSagaContext } from 'sagas';
import { ActionType, getType } from "typesafe-actions";
import { apiTaskWrapper } from './saga-commons';

export function* watchPropertyListFetchRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(propertyListActions.fetch),
    apiTaskWrapper(fetchPropertyList),
    context,
  );
}

function formToCriteria(form: PropertyStockSearchForm, locale: string): CAPropertyStockSearchDTO {
  let searchDto = {} as Partial<CAPropertyStockSearchDTO>;
  searchDto = { ...form };

  if (Array.isArray(form.priceDisplay)) {
    searchDto = {
      ...searchDto,
      price: [
        form.priceDisplay[0] === Infinity ? -Number.MAX_SAFE_INTEGER : priceFromView(form.priceDisplay[0], locale),
        form.priceDisplay[1] === Infinity ? Number.MAX_SAFE_INTEGER : priceFromView(form.priceDisplay[1], locale),
      ],
    };
  }

  if (Array.isArray(form.rent)) {
    searchDto = {
      ...searchDto,
      rent: [
        form.rent[0] === Infinity ? -Number.MAX_SAFE_INTEGER : form.rent[0],
        form.rent[1] === Infinity ? Number.MAX_SAFE_INTEGER : form.rent[1],
      ],
    };
  }

  if (Array.isArray(form.net)) {
    searchDto = {
      ...searchDto,
      net: [
        form.net[0] === Infinity ? -Number.MAX_SAFE_INTEGER : form.net[0],
        form.net[1] === Infinity ? Number.MAX_SAFE_INTEGER : form.net[1],
      ],
    };
  }

  // if (Array.isArray(form.gross)) {
  //   searchDto = {
  //     ...searchDto,
  //     gross: [
  //       form.gross[0] === Infinity ? -Number.MAX_SAFE_INTEGER : form.gross[0],
  //       form.gross[1] === Infinity ? Number.MAX_SAFE_INTEGER : form.gross[1],
  //     ],
  //   };
  // }

  searchDto = {
    ...searchDto,
    price: (searchDto.buyOrRent === 'BUY' || !isNonEmpty(searchDto.buyOrRent)) ?  searchDto.price : undefined,
    rent: (searchDto.buyOrRent === 'RENT' || !isNonEmpty(searchDto.buyOrRent)) ?  searchDto.rent : undefined,
  }

  Object.keys(searchDto).map(key => {
    switch (key) {
      case 'page':
      case 'limit':
        break;
      case 'buildingName':
        searchDto['buildingName'] = searchDto.buildingName?.trim(); break;
      case 'street':
        searchDto['street'] = searchDto.street?.trim(); break;
      case 'room':
        searchDto['room'] = form.roomEnabled ? searchDto.room : undefined; break;
      case 'suite':
        searchDto['suite'] = form.suiteEnabled ? searchDto.suite : undefined; break;
      case 'bathroom':
        searchDto['bathroom'] = form.bathroomEnabled ? searchDto.bathroom : undefined; break;
      case 'balcony':
        searchDto['balcony'] = form.balconyEnabled ? searchDto.balcony : undefined; break;
      case 'hasHelperRoom':
        searchDto['hasHelperRoom'] = form.helperRoomEnabled ? searchDto.hasHelperRoom : undefined; break;
      default:
        if (!isNonEmpty(searchDto[key as keyof CAPropertyStockSearchDTO])) {
          searchDto[key as keyof CAPropertyStockSearchDTO] = undefined;
        }
    }
  })

  return { ...searchDto} as CAPropertyStockSearchDTO; 
}

function formToCriteriaToBeSaved(form: PropertyStockSearchForm, locale: string): CACustomerSavedSearchDTO {
  let criteriaDto = { ...form } as Partial<CACustomerSavedSearchDTO>;

  if (Array.isArray(form.priceDisplay)) {
    criteriaDto = {
      ...criteriaDto,
      price: [
        form.priceDisplay[0] === Infinity ? -Number.MAX_SAFE_INTEGER : priceFromView(form.priceDisplay[0], locale),
        form.priceDisplay[1] === Infinity ? Number.MAX_SAFE_INTEGER : priceFromView(form.priceDisplay[1], locale),
      ],
    };
  }

  if (Array.isArray(form.rent)) {
    criteriaDto = {
      ...criteriaDto,
      rent: [
        form.rent[0] === Infinity ? -Number.MAX_SAFE_INTEGER : form.rent[0],
        form.rent[1] === Infinity ? Number.MAX_SAFE_INTEGER : form.rent[1],
      ],
    };
  }

  if (Array.isArray(form.net)) {
    criteriaDto = {
      ...criteriaDto,
      net: [
        form.net[0] === Infinity ? -Number.MAX_SAFE_INTEGER : form.net[0],
        form.net[1] === Infinity ? Number.MAX_SAFE_INTEGER : form.net[1],
      ],
    };
  }

  criteriaDto = {
    ...criteriaDto,
    buyOrRent: form.buyOrRent,
    price: form.buyOrRent === 'BUY' ?  criteriaDto.price : undefined,
    rent: form.buyOrRent === 'RENT' ?  criteriaDto.rent : undefined,
  }

  Object.keys(form).map(key => {
    switch (key) {
      case 'buildingName':
        criteriaDto['buildingName'] = form.buildingName?.trim(); break;
      case 'street':
        criteriaDto['street'] = form.street?.trim(); break;
      case 'room':
        criteriaDto['room'] = form.roomEnabled ? form.room : undefined; break;
      case 'suite':
        criteriaDto['suite'] = form.suiteEnabled ? form.suite : undefined; break;
      case 'bathroom':
        criteriaDto['bathroom'] = form.bathroomEnabled ? form.bathroom : undefined; break;
      case 'balcony':
        criteriaDto['balcony'] = form.balconyEnabled ? form.balcony : undefined; break;
      case 'hasHelperRoom':
        criteriaDto['hasHelperRoom'] = form.helperRoomEnabled ? form.hasHelperRoom : undefined; break;
      default:
        break;
    }
  })

  criteriaDto = { 
    ...criteriaDto, 
    features: [ 
      ...form.district.map(v => ({ type: 'DISTRICT', value: v })),
      ...form.facing.map(v => ({ type: 'FACING', value: v })),
      ...form.usage.map(v => ({ type: 'USAGE', value: v })),
      ...form.deco.map(v => ({ type: 'DECO', value: v })),
      ...form.view.map(v => ({ type: 'VIEW', value: v })),
      ...form.otherFeatures.map(v => ({ type: 'OTHER_FEATURES', value: v })),
      ...form.others.map(v => ({ type: 'OTHERS', value: v })),
      ...form.clubHouseFacilities.map(v => ({ type: 'CLUB_HOUSE_FACILITIES', value: v })),
      ...form.primarySchoolNet.map(v => ({ type: 'PRIMARY_SCHOOL_NET', value: v })),
      ...form.secondarySchoolNet.map(v => ({ type: 'SECONDARY_SCHOOL_NET', value: v }))
    ] as CACustomerSavedSearchFeatureDTO[]
  }
  return criteriaDto as CACustomerSavedSearchDTO;
}

function isEmptyRange(range: number[] | undefined) {
  return !range || (range[0] === -Number.MAX_SAFE_INTEGER && range[1] === Number.MAX_SAFE_INTEGER);
}

function savedCriteriaToForm(savedCriteria: CACustomerSavedSearchDTO, locale: string): PropertyStockSearchForm {
  let form = { ...savedCriteria } as Partial<PropertyStockSearchForm>;
  
  form = {
    ...form,
    priceDisplay: [
      savedCriteria.price[0] === null ? 0 : priceToView(savedCriteria.price[0], locale),
      savedCriteria.price[1] === null ? Infinity : priceToView(savedCriteria.price[1], locale),
    ],
  };
   
  form = {
    ...form,
    rent: [
      savedCriteria.rent[0] === null ? 0 : savedCriteria.rent[0],
      savedCriteria.rent[1] === null ? Infinity : savedCriteria.rent[1]
    ],
  };
   
  form = {
    ...form,
    net: [
      savedCriteria.net[0] === null ? 0 : savedCriteria.net[0],
      savedCriteria.net[1] === null ? Infinity : savedCriteria.net[1],
    ],
  };

  form = {
    ...form,
    buyOrRent: savedCriteria.buyOrRent,
    priceDisplay: savedCriteria.buyOrRent === 'BUY' ?  form.priceDisplay : [0, Infinity],
    rent: savedCriteria.buyOrRent === 'RENT' ?  form.rent : [0, Infinity],
  }

  const features = savedCriteria.features;
  
  Object.keys(savedCriteria).map(key => {
    switch (key) {
      case 'room':
        if (isNonEmpty(savedCriteria.room)) {
          form['room'] = savedCriteria.room;
          form['roomEnabled'] = true;
        }
        break;
      case 'suite':
        if (isNonEmpty(savedCriteria.suite)) {
          form['suite'] = savedCriteria.suite;
          form['suiteEnabled'] = true;
        }
        break;
      case 'bathroom':
        if (isNonEmpty(savedCriteria.bathroom)) {
          form['bathroom'] = savedCriteria.bathroom;
          form['bathroomEnabled'] = true;
        }
        break;
      case 'balcony':
        if (isNonEmpty(savedCriteria.balcony)) {
          form['balcony'] = savedCriteria.balcony;
          form['balconyEnabled'] = true;
        }
        break;
      case 'hasHelperRoom':
        if (isNonEmpty(savedCriteria.hasHelperRoom)) {
          form['hasHelperRoom'] = savedCriteria.hasHelperRoom;
          form['helperRoomEnabled'] = true;
        }
        break;
      default:
        break;
    }
  });

  form = {
    ...form, 
    buildingName: savedCriteria.buildingName,
    street: savedCriteria.street,
    district: features.filter(f => f.type === 'DISTRICT').map(f => f.value),
    facing: features.filter(f => f.type === 'FACING').map(f => f.value),
    deco: features.filter(f => f.type === 'DECO').map(f => f.value),
    usage: features.filter(f => f.type === 'USAGE').map(f => f.value),
    view: features.filter(f => f.type === 'VIEW').map(f => f.value),
    otherFeatures: features.filter(f => f.type === 'OTHER_FEATURES').map(f => f.value),
    others: features.filter(f => f.type === 'OTHERS').map(f => f.value),
    clubHouseFacilities: features.filter(f => f.type === 'CLUB_HOUSE_FACILITIES').map(f => f.value),
    primarySchoolNet: features.filter(f => f.type === 'PRIMARY_SCHOOL_NET').map(f => f.value),
    secondarySchoolNet: features.filter(f => f.type === 'SECONDARY_SCHOOL_NET').map(f => f.value),
  }

  return form as PropertyStockSearchForm;
}

export function* fetchPropertyList(context: PASSagaContext, action: ActionType<typeof propertyListActions['fetch']>) {
  const { isSwitchingPage } = action.payload;
  
  const locale = yield select((state: IRootState) => state.locale.locale);

  const { token } = yield select((state: IRootState) => state.login);
  const searchForm = yield select((state: IRootState) => state.propertyList.contents);
  const criteria = formToCriteria(searchForm, locale);
  yield put(propertyListActions.edit('saveSearchCriteria', false));
  const { data, error }: ApiPageResult<CAPropertyStockListItemDTO> = yield call(propertyApi.getList, 
    {...criteria, page: isSwitchingPage ? criteria.page + 1 : 0},
    token);

  if (error) {
    throw ApiError.of(error!);
  }

  if (criteria.district?.length || !isEmptyRange(criteria.price) || !isEmptyRange(criteria.net)) {
    yield call(trackSearchGoal,
      criteria.district?.[0] ?? '',
      criteria.price ?? [],
      criteria.net ?? [],
    );
  }

  const properties = data!.content;

  const { data: soldLeasedData, error: soldLeasedError }: ApiResult<CAPropertyStockListItemDTO[]> = 
    yield call(propertyApi.getSoldLeasedList, criteria, token);

  if (isSwitchingPage) {
    yield put(propertyListActions.edit('page', criteria.page + 1 ));
    yield put(propertyListActions.appendNextPage({ properties, hasMore: data!.currentPage+1 < data!.totalPages }));
    yield put(propertyListActions.refreshSoldLeased({ soldLeasedProperties: soldLeasedData! }))
  } else {
    yield put(propertyListActions.edit('page', 0 ));
    yield put(propertyListActions.refresh({ properties, hasMore: data!.currentPage+1 < data!.totalPages }));
    yield put(propertyListActions.refreshSoldLeased({ soldLeasedProperties: soldLeasedData! }))
    // yield call(context.browserHistory.push, '/properties');
  }
  
  /*TODO: add soldLeasedList to reducer*/
}

export function* watchPropertyListAddBookmark(context: PASSagaContext) {
  yield takeLeading(
    [ getType(propertyListActions.addBookmark), 
      getType(propertyStockDetailActions.addBookmark),
      getType(inlinePropertyListActions.addBookmark),
      getType(bookmarkListActions.addBookmark)],
    apiTaskWrapper(addBookmark),
    context,
  );
}

function* addBookmark(context: PASSagaContext, action: ActionType<typeof propertyListActions['addBookmark']> 
  | ActionType<typeof propertyStockDetailActions['addBookmark']> | ActionType<typeof inlinePropertyListActions['addBookmark']>
  | ActionType<typeof bookmarkListActions['addBookmark']>) {
  const token: string | undefined = yield select((state: IRootState) => state.login.token);
  
  if (!token) {
    yield sagaJump(jumpers.toLogin(), context.browserHistory);
    return;
  }

  yield call(customerApi.addBookmark, action.payload.caPropertyStockId, token);

}

export function* watchPropertyListDeleteBookmark(context: PASSagaContext) {
  yield takeLeading(
    [ getType(propertyListActions.deleteBookmark), 
      getType(propertyStockDetailActions.deleteBookmark),
      getType(inlinePropertyListActions.deleteBookmark),
      getType(bookmarkListActions.deleteBookmark)],
    apiTaskWrapper(deleteBookmark),
    context,
  );
}

function* deleteBookmark(context: PASSagaContext, action: ActionType<typeof propertyListActions['deleteBookmark']> 
  | ActionType<typeof propertyStockDetailActions['deleteBookmark']> | ActionType<typeof inlinePropertyListActions['deleteBookmark']>
  | ActionType<typeof bookmarkListActions['deleteBookmark']>) {
  const token: string | undefined = yield select((state: IRootState) => state.login.token);
  
  if (!token) {
    yield sagaJump(jumpers.toLogin(), context.browserHistory);
    return;
  }

  yield call(customerApi.deleteBookmark, action.payload.caPropertyStockId, token);
}

export function* watchVisitBookmark(context: PASSagaContext) {
  yield takeLeading(
    getType(bookmarkListActions.visitBookmark),
    apiTaskWrapper(visitBookmark),
    context,
  );
}

function* visitBookmark(context: PASSagaContext, action: ActionType<typeof bookmarkListActions['visitBookmark']>) {
  const token: string | undefined = yield select((state: IRootState) => state.login.token);
  
  if (!token) {
    // yield sagaJump(jumpers.toLogin(), context.browserHistory);
    return;
  }

  yield call(customerApi.visitBookmark, action.payload.caPropertyStockId, token);
}

export function* watchFetchSavedSearchCriteriaRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(saveSearchActions.fetchSavedSearchCriterias),
    apiTaskWrapper(fetchSavedSearchCriteria),
    context,
  );
}

function* fetchSavedSearchCriteria(context: PASSagaContext, action: ActionType<typeof saveSearchActions['fetchSavedSearchCriterias']>) {
  const token: string | undefined = yield select((state: IRootState) => state.login.token);
  const locale = yield select((state: IRootState) => state.locale.locale);

  if (!token) {
    yield sagaJump(jumpers.toLogin(), context.browserHistory);
    return;
  }

  const { data, error }: ApiResult<CACustomerSavedSearchDTO[]> = yield call(customerApi.getSavedSearchCriteria, token);
  
  if (error) {
    throw ApiError.of(error);
  } else {
    yield put(saveSearchActions.updateSavedSearchCriterias({ savedCriterias: data?.map(v => ({ criteriaId: v.id, title: v.title, searchDate: v.searchDate, contents: savedCriteriaToForm(v, locale) })) ?? [] }));
  }
}

export function* watchSaveSearchCriteriaRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(saveSearchActions.addSearchCriteria),
    apiTaskWrapper(saveSearchCriteria),
    context,
  );
}

function* saveSearchCriteria(context: PASSagaContext, action: ActionType<typeof saveSearchActions['addSearchCriteria']>) {
  const token: string | undefined = yield select((state: IRootState) => state.login.token);
  const { contents: criteria } = yield select((state: IRootState) => state.propertyList);
  const { saveSearchTitle } = yield select((state: IRootState) => state.saveSearch);
  const locale = yield select((state: IRootState) => state.locale.locale);

  if (!token) {
    yield sagaJump(jumpers.toLogin(), context.browserHistory);
    return;
  }

  const { data, error }: ApiResult<boolean> = yield call(customerApi.saveSearchCriteria, saveSearchTitle, formToCriteriaToBeSaved(criteria, locale), token);

  if (error) {
    throw ApiError.of(error);
  } else {
    yield put(saveSearchActions.openSaveSearchSuccessDialog());
  }
}

export function* watchDeleteSavedSearchCriteriaRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(saveSearchActions.deleteSavedSearchCriteria),
    apiTaskWrapper(deleteSavedSearchCriteria),
    context,
  );
}

function* deleteSavedSearchCriteria(context: PASSagaContext, action: ActionType<typeof saveSearchActions['deleteSavedSearchCriteria']>) {
  const token: string | undefined = yield select((state: IRootState) => state.login.token);
  
  if (!token) {
    yield sagaJump(jumpers.toLogin(), context.browserHistory);
    return;
  }

  const { data, error }: ApiResult<boolean> = yield call(customerApi.deleteSavedSearchCriteria, action.payload.criteriaId, token);

  if (error) {
    throw ApiError.of(error);
  } else {
    yield put(saveSearchActions.fetchSavedSearchCriterias());
  }

}

export function* watchPropertyMapPointsFetch(context: PASSagaContext) {
  yield takeLeading(
    getType(propertyMapActions.fetchPoints),
    apiTaskWrapper(fetchPoints, { noMask: true }),
    context,
  );
}

export function* fetchPoints(context: PASSagaContext, action: ActionType<typeof propertyMapActions['fetchPoints']>) {
  const locale = yield select((state: IRootState) => state.locale.locale);
  const { token } = yield select((state: IRootState) => state.login);
  const searchForm = yield select((state: IRootState) => state.propertyList.contents);

  const criteria = formToCriteria(searchForm, locale);
  
  const { data, error }: ApiPageResult<CAPropertyStockMapItemDTO> = yield call(propertyApi.getMapItems, criteria, token);

  if (error) {
    throw ApiError.of(error);
  }

  yield put(propertyMapActions.pointsUpdated({ points: data!.content }));
  
}

export function* watchPropertyMapFilteredFetch(context: PASSagaContext) {
  yield takeLatest(
    getType(propertyMapActions.fetchFiltered),
    apiTaskWrapper(fetchFiltered, { noMask: true }),
    context,
  );
}

export function* fetchFiltered(context: PASSagaContext, action: ActionType<typeof propertyMapActions['fetchFiltered']>) {
  const { isSwitchingPage } = action.payload;
  const locale = yield select((state: IRootState) => state.locale.locale);
  const { token } = yield select((state: IRootState) => state.login);
  const searchForm = yield select((state: IRootState) => state.propertyList.contents);
  const { caPropertyStockIdList: pidList, filtered, page }: IRootState['propertyMap'] = yield select((state: IRootState) => state.propertyMap);

  const criteria = formToCriteria(searchForm, locale);

  yield put(propertyListActions.edit('saveSearchCriteria', false));
  
  const { data, error }: ApiPageResult<CAPropertyStockListItemDTO> = yield call(propertyApi.getList, {
    ...criteria, includedPidList: pidList, page: isSwitchingPage ? page + 1 : 0
  }, token);

  const { data: soldLeasedData, error: soldLeasedError }: ApiResult<CAPropertyStockListItemDTO[]> = 
    yield call(propertyApi.getSoldLeasedList, criteria, token);

  if (error) {
    yield put(propertyMapActions.filteredUpdated({ filtered: [], hasMore: false, append: isSwitchingPage, }));
    throw ApiError.of(error);
  } else {
    yield put(propertyListActions.refreshSoldLeased({ soldLeasedProperties: soldLeasedData! }))
    yield put(propertyMapActions.filteredUpdated({
      filtered: data!.content,
      hasMore: data!.currentPage+1 < data!.totalPages,
      append: isSwitchingPage,
    }));
  }
}

export function* watchPropertyDetailFetchRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(propertyStockDetailActions.doFetch),
    apiTaskWrapper(fetchPropertyDetail),
    context,
  );
}

export function* fetchPropertyDetail(context: PASSagaContext, action: ActionType<typeof propertyStockDetailActions['doFetch']>) {
 
  const { propertyNo } = action.payload;
  const { token } = yield select((state: IRootState) => state.login);
  const { data, error }: ApiResult<CAPropertyStockDetailDTO> = yield call(propertyApi.getDetail, 
    propertyNo,
    token
  );

  if (error) {
    if (ApiError.isErrorCode(error, 'ERR_NOT_FOUND')) {
      yield put(propertyStockDetailActions.update({ propertyStock: null }));
    } else {
      throw ApiError.of(error!);
    }
  } else {
    yield put(propertyStockDetailActions.update({ propertyStock: data ?? null }));
  } 
  // else {
  //   yield call(context.browserHistory.push, '/properties');
  // }

}

export function* watchInlinePropertyListFetchRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(inlinePropertyListActions.fetch),
    apiTaskWrapper(fetchInlinePropertyList),
    context,
  );
}

export function* fetchInlinePropertyList(context: PASSagaContext, action: ActionType<typeof inlinePropertyListActions['fetch']>) {
  const { isSwitchingPage } = action.payload;
  const locale = yield select((state: IRootState) => state.locale.locale);
  const { token } = yield select((state: IRootState) => state.login);
  const criteria = yield select((state: IRootState) => state.inlinePropertyList.contents);
  const { data, error }: ApiPageResult<CAPropertyStockListItemDTO> = yield call(propertyApi.getList, 
    {...formToCriteria(criteria, locale), page: isSwitchingPage ? criteria.page + 1 : 0},
    token);

  const properties = data?.content!;

  if (error) {
    throw ApiError.of(error!);
  } else if (isSwitchingPage) {
    yield put(inlinePropertyListActions.edit('page', criteria.page + 1 ));
    yield put(inlinePropertyListActions.appendNextPage({ properties, hasMore: data!.currentPage+1 < data!.totalPages }));
  } else {
    yield put(inlinePropertyListActions.edit('page', 0 ));
    yield put(inlinePropertyListActions.update({ properties, hasMore: data!.currentPage+1 < data!.totalPages }));
    // yield call(context.browserHistory.push, '/properties');
  }
}

export function* watchBookmarkListFetchRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(bookmarkListActions.fetch),
    apiTaskWrapper(fetchBookmarkList),
    context,
  );
}

export function* fetchBookmarkList(context: PASSagaContext, action: ActionType<typeof bookmarkListActions['fetch']>) {
  const { isSwitchingPage } = action.payload;
  const locale = yield select((state: IRootState) => state.locale.locale);
  const { token } = yield select((state: IRootState) => state.login);

  // if (!token && !loggedIn) {
  //   yield sagaJump(jumpers.replaceCurrentToLogin(), context.browserHistory);
  //   return;
  // }

  const criteria = yield select((state: IRootState) => state.bookmarkList.contents);
  const { data, error }: ApiPageResult<CAPropertyStockListItemDTO> = yield call(customerApi.getBookmarks, 
    {...formToCriteria(criteria, locale), page: isSwitchingPage ? criteria.page + 1 : 0},
    token ?? '');

  const properties = data?.content!;

  if (error) {
    throw ApiError.of(error!);
  } else if (isSwitchingPage) {
    yield put(bookmarkListActions.edit('page', criteria.page + 1 ));
    yield put(bookmarkListActions.appendNextPage({ properties, hasMore: data!.currentPage+1 < data!.totalPages }));
  } else {
    yield put(bookmarkListActions.edit('page', 0 ));
    yield put(bookmarkListActions.update({ properties, hasMore: data!.currentPage+1 < data!.totalPages }));
  }
}

export function* watchFullTransactionListFetchRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(fullTransactionListActions.doFetchTransactions),
    apiTaskWrapper(fetchFullTransactionList),
    context,
  );
}

export function* fetchFullTransactionList(context: PASSagaContext, action: ActionType<typeof fullTransactionListActions['doFetchTransactions']>) {
  const locale = yield select((state: IRootState) => state.locale.locale);
  const { token } = yield select((state: IRootState) => state.login);

  const { data, error }: ApiPageResult<TransactionDTO> = yield call(propertyApi.getPropertyTransactions, 
    action.payload.propertyNo, action.payload.page, action.payload.sort, action.payload.direction,
    token
  );

  const transactions = data?.content!;

  if (error) {
    throw ApiError.of(error!);
  } else {
    yield put(fullTransactionListActions.update({
      transactions,
      totalPages: data?.totalPages ?? 0,
      currentPage: data?.currentPage ?? 0,
    }));
  }
}

export default [ watchPropertyListFetchRequested, watchPropertyListAddBookmark,
  watchPropertyListDeleteBookmark,
  watchPropertyMapPointsFetch,
  watchPropertyMapFilteredFetch,
  watchPropertyDetailFetchRequested,
  watchInlinePropertyListFetchRequested,
  watchBookmarkListFetchRequested,
  watchVisitBookmark,
  watchFetchSavedSearchCriteriaRequested,
  watchSaveSearchCriteriaRequested,
  watchDeleteSavedSearchCriteriaRequested,
  watchFullTransactionListFetchRequested,
];