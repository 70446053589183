import useLocale from 'common/hooks/useLocale';
import { addTouchableHoveringSupport } from 'common/polyfills/touch-polyfill';
import FormDistrictSelectField from 'components/form-controls/FormDistrictSelectField';
import FormTextField from 'components/form-controls/FormTextField';
import { FormBinder } from 'components/form-controls/IFormBinder';
import DialogPropertyInfo, { DialogPropertyInfoProps } from 'components/property/detail/DialogPropertyInfo';
import React from 'react';
import classes from './EnquiryDialog.module.css';
import ModalDialog from './ModalDialog';
import ModalDialogActions, { ModalDialogActionButton } from './ModalDialogActions';
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'common/theme/breakpoints.module.scss';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';



export interface EnquiryDialogProps {
  open: boolean;
  onHide?: () => any;

  regionBundles: RegionBundles;

  bindEmail?: FormBinder<string>;
  bindContact?: FormBinder<string>;
  bindName?: FormBinder<string>;
  bindDistricts?: FormBinder<string[]>;

  onContactClick?: AnyFn;
  onSameDistrictAgentClick?: AnyFn;

  propertySpecific?: boolean;
  dialogPropertyInfoProps?: DialogPropertyInfoProps;
}

addTouchableHoveringSupport(
  `.${classes['enquiry-action']}`,
  [],
  // classes['no-hover'],
);

export default function EnquiryDialog(props: EnquiryDialogProps) {
  const { open, onHide,
    bindEmail, bindContact, bindName, bindDistricts, regionBundles,
    onContactClick, onSameDistrictAgentClick, propertySpecific, dialogPropertyInfoProps
  } = props;
  const { langEnquiryDialog } = useLocale();
  const isMobile = useMediaQuery({ maxWidth: breakpoints.sm });
  const { searchType } = useSelector((state: IRootState) => state.home);


  return <ModalDialog size={isMobile ? 'sm' : undefined} title={langEnquiryDialog.titleEnquiry} show={open} onHide={onHide}>
    <div className={classes['enquiry-dialog-body']}>
      <div className={classes['enquiry-dialog-content']}>
        {propertySpecific ? <div className={classes['property-info']}>
          <DialogPropertyInfo {...dialogPropertyInfoProps as DialogPropertyInfoProps} />
          </div>: null }
        {/* Message */}
        <div className={classes['enquiry-message']}>{langEnquiryDialog.msgProvideInfo}</div>
        {/* Forms */}
        <div className={classes['form-row']}>
          <FormTextField label={langEnquiryDialog.captionName} bind={bindName} />
        </div>
        <div className={classes['form-row']}>
          <FormTextField label={langEnquiryDialog.captionContact} bind={bindContact} />
        </div>
        <div className={classes['form-row']}>
          <FormTextField label={langEnquiryDialog.captionEmail} bind={bindEmail} />
        </div>
        {!propertySpecific ? <div className={classes['form-row']}>
          <FormDistrictSelectField regionBundles={regionBundles} label={langEnquiryDialog.captionDistrict} bind={bindDistricts} />
        </div> : null}
      </div>

      {/* <div className={classes['enquiry-dialog-actions']}>
        <div className={clsx(classes['enquiry-action'], classes['left'])} onClick={onContactClick}>
          {langEnquiryDialog.actionContactMe}
        </div>
        <div className={clsx(classes['enquiry-action'], classes['right'])} onClick={onSameDistrictAgentClick}>
          {langEnquiryDialog.actionSameDistrictAgents}
        </div>
      </div> */}

      {propertySpecific ? <ModalDialogActions>
        <ModalDialogActionButton onClick={onContactClick}>
          {langEnquiryDialog.actionContactMeLeadAgents}
        </ModalDialogActionButton>
      </ModalDialogActions> : searchType ==='SELLER_ENQUIRY' ? <ModalDialogActions>
        <ModalDialogActionButton onClick={onContactClick}>
          {langEnquiryDialog.actionContactMe}
        </ModalDialogActionButton>
      </ModalDialogActions>:<ModalDialogActions>
        <ModalDialogActionButton onClick={onContactClick}>
          {langEnquiryDialog.actionContactMe}
        </ModalDialogActionButton>
        <ModalDialogActionButton onClick={onSameDistrictAgentClick}>
          {langEnquiryDialog.actionSameDistrictAgents}
        </ModalDialogActionButton>
      </ModalDialogActions>}
    </div>
  </ModalDialog>
}