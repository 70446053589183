import { BASE_URL } from 'api';
import useSimpleFormBinder from 'common/hooks/useFormBinder';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import { jumpers, useJump } from 'common/theme/jumper';
import { isMobileUI } from 'common/utils/utils';
import AgentListViewDesktop from 'components/agent-list/AgentListViewDesktop';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'reducers';
import { agentContactActions } from 'reducers/agent-contact';
import { agentDetailActions } from 'reducers/agent-detail';
import { agentListActions } from 'reducers/agent-list';
import { agentRatingCommentActions } from 'reducers/agent-rating-comment';
import { loginSelectors } from 'reducers/login';
import AgentListViewMobile from '../../components/agent-list/AgentListViewMobile';
import { homeActions } from 'reducers/home';


// const _AgentListPage = connect((state: IRootState) => ({
//   agents: state.agentList.agents,
//   hasMore: state.agentList.hasMore,
// }), (dispatch) => ({
//   onRequestNextPage: () => dispatch(agentListActions.doFetchAgents({ isSwitchingPage: true })),
// }))(AgentListViewMobile);
// TODO: DUMMY
let idx = 0;

const AgentListView = isMobileUI() ? AgentListViewMobile : AgentListViewDesktop;

const AgentListPage = () => {
  const dispatch = useDispatch();
  const { agents, hasMore, criteria } = useSelector((state: IRootState) => state.agentList);
  const layoutProps = useLayoutProps();
  const localeBundles = useLocale();
  const history = useHistory();
  const jump = useJump();
  const loggedIn = useSelector(loginSelectors.loggedIn());

  useEffect(() => {
    dispatch(agentListActions.doFetchAgents({ isSwitchingPage: false }));
  }, [ dispatch ]);

  const bindDistricts = useSimpleFormBinder(
    criteria.districts,
    (updated) => {
      dispatch(agentListActions.updateCriteria({ districts: updated }));
      dispatch(agentListActions.doFetchAgents({ isSwitchingPage: false }));
    },
  );

  dispatch(homeActions.updateSearchType('BUYRENT'));

  const regionBundles = useLocale();

  const allDistrictOptions = useMemo(() => {
    return {
      ...regionBundles.districtHkiOptions,
      ...regionBundles.districtKltOptions,
      ...regionBundles.districtNtOptions,
    };
  }, [regionBundles]);

  return <AgentListView
    {...layoutProps}
    onBackClick={() => history.goBack()}
    bindDistricts={bindDistricts}
    regionBundles={localeBundles}
    agents={agents.map((agent) => ({ ...agent, photoFilename: agent?.photoFilename ? `${BASE_URL}/files/${agent?.photoFilename}` : '' }))}
    hasMore={hasMore}

    onRequestNextPage={() => dispatch(agentListActions.doFetchAgents({ isSwitchingPage: true }))}
    onRefresh={() => dispatch(agentListActions.doFetchAgents({ isSwitchingPage: false }))}

    doDisplayDetail={(agentId) => {
      // assume that we can definitely retrieve from current list.
      const agentInfo = agents.filter(agent => agent.id === agentId)[0]!;
      dispatch(agentDetailActions.openDialog({
        id: agentId,
        image: agentInfo?.photoFilename ? `${BASE_URL}/files/${agentInfo?.photoFilename}` : '',
        chineseName: agentInfo.chineseName ?? '',
        englishName: agentInfo.englishName ?? '',
        rating: agentInfo.rating ?? 0,
        maxRating: agentInfo.maxRating,
        district: agentInfo.districts?.map(d => allDistrictOptions[d] ?? d)?.join(", "),
      }));
      dispatch(agentDetailActions.doFetchComments({ uid: agentId }));
      // dispatch(agentDetailActions.updateComments({
      //   comments: (new Array(20)).fill(true).map(_ => ({
      //     id: idx++,
      //     rating: 3.5,
      //     comment: '勤奮有幹勁，做事效率高，多樓盤. 勤奮有幹勁，做事效率高，多樓盤. 勤奮有幹勁，做事效率高，多樓盤. 勤奮有幹勁，做事效率高，多樓盤'
      //   })),
      //   hasMore: false,
      // }))
    }}

    doDisplayContact={(agentId) => {
      // assume that we can definitely retrieve from current list.
      const agentInfo = agents.filter(agent => agent.id === agentId)[0]!;
      dispatch(agentContactActions.openDialog({
        id: agentId,
        image: agentInfo?.photoFilename ? `${BASE_URL}/files/${agentInfo?.photoFilename}` : '',
        chineseName: agentInfo.chineseName ?? '',
        englishName: agentInfo.englishName ?? '',
        rating: agentInfo.rating ?? 0,
        maxRating: agentInfo.maxRating,
        entry: 'D',
      }));
    }}

    doDisplayRatingForm={(agentId) => {
      // assume that we can definitely retrieve from current list.
      if (!loggedIn) {
        jump(jumpers.toLogin());
      } else {
        const agentInfo = agents.filter(agent => agent.id === agentId)[0]!;
        dispatch(agentRatingCommentActions.openDialog({
          id: agentId,
          image: agentInfo?.photoFilename ? `${BASE_URL}/files/${agentInfo?.photoFilename}` : '',
          chineseName: agentInfo.chineseName ?? '',
          englishName: agentInfo.englishName ?? '',
          rating: agentInfo.rating ?? 0,
          maxRating: agentInfo.maxRating,
          entry: 'D',
        }));
      }
    }}
  />;
};

export default AgentListPage;