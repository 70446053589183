import { BASE_URL } from 'api';
import CAPropertyStockListItemDTO, { getLocalePunchline } from 'common/dto/CAPropertyStockListItemDTO';
import useComponentDidMount from 'common/hooks/useComponentDidMount';
import useSimpleFormBinder, { useLocalBinder } from 'common/hooks/useFormBinder';
import useLayoutProps from 'common/hooks/useLayoutProps';
import useLocale from 'common/hooks/useLocale';
import usePrevious from 'common/hooks/usePrevious';
import { jumpers, useJump } from 'common/theme/jumper';
import { isNonEmpty, multiLang, priceFromView, priceToView } from 'common/utils/utils';
import { PropertyResultMapListItemProps } from 'components/property/search/PropertyResultMapListItem';
import SearchScreenDesktop from 'components/property/search/SearchScreenDesktop';
import { getSearchTitle } from 'containers/home/AdvancedSearchPage';
import deepEqual from 'deep-equal';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { IRootState } from 'reducers';
import { alertConfirmDialogActions } from 'reducers/alert-confirm-dialog';
import { useCommonFormReducer } from 'reducers/common/form';
import { enquiryDialogActions } from 'reducers/enquiry-dialog';
import { homeActions } from 'reducers/home';
import { propertyListActions } from 'reducers/property-list';
import { propertyMapActions } from 'reducers/property-map';
import { saveSearchActions } from 'reducers/save-search';
import { format } from 'util';
import { getMergedProperties } from './PropertySearchListingPage';

const EMPTY_ARRAY: any[] = [];

export default function PropertySearchDesktopPage() {
  const form = useCommonFormReducer(
    (state: IRootState) => state.propertyList,
    propertyListActions.edit,
    propertyListActions.addFormError,
    []
  );

  const layoutProps = useLayoutProps();
  const dispatch = useDispatch();
  const jump = useJump();
  const history = useHistory();

  const { loggedIn } = useSelector((state: IRootState) => state.login);

  const localeState = useLocale();
  const { locale,
    lang, langProperty,
    usageOptions, facingOptions, decoOptions, viewOptions, otherFeatureOptions, primarySchoolNetOptions,
    secondarySchoolNetOptions, districtHkiOptions, districtKltOptions, districtNtOptions,
    otherOptions, clubHouseFacilitiesOptions, levelOptions,
  } = localeState;

  const { soldLeasedProperties, contents: searchForm } = useSelector((state: IRootState) => state.propertyList);
  const mapFiltered = useSelector((state: IRootState) => state.propertyMap.filtered);
  const mapPoints = useSelector((state: IRootState) => state.propertyMap.points);
  const { filteredLoading, filteredHasMore } = useSelector((state: IRootState) => state.propertyMap);
  const { DISPLAY_FIRST_SOLD_LEASED_PROPERTY_AFTER_N_PROPERTIES, 
    DISPLAY_REST_SOLD_LEASED_PROPERTIES_AFTER_N_PROPERTIES
  } = useSelector((state: IRootState) => state.systemSettings.ClientApp.PROPERTY_STOCK);
  const { openSavedSearchCriteriasDialog } = useSelector((state: IRootState) => state.saveSearch);

  const mapItems = useMemo(() => mapPoints.filter(p => p.lat && p.lng).map(p => ({
    id: p.caPropertyStockId ?? (p as any).propertyStockId,
    lat: p.lat,
    lng: p.lng,
    district: p.district,
  })), [ mapPoints ]);

  // On Init...
  useComponentDidMount(() => {
    if (mapFiltered.length === 0) {
      // If property list are empty, try fetch once
      dispatch(propertyMapActions.fetchFiltered({ pidList: [], isSwitchingPage: false }));
    }
    dispatch(propertyMapActions.fetchPoints());
  });

  useEffect(() => {
    if (!openSavedSearchCriteriasDialog) {
      dispatch(propertyMapActions.fetchPoints());
      dispatch(propertyMapActions.fetchFiltered({ pidList: [], isSwitchingPage: false }));
    }
  }, [ openSavedSearchCriteriasDialog ]);

  useEffect(() => {
    // console.log("print fetchFiltered: on mapPoints change");
    dispatch(propertyMapActions.fetchFiltered({
      pidList: mapPoints.map(p => p.caPropertyStockId),
      isSwitchingPage: false,
    }));
  }, [ mapPoints ]);
  
  //Add useLocation for monitoring the entries from homePage's tags
  let location = useLocation();
  const searchFormRef = useRef(searchForm);

  // Update the values of searchForm for comparing the init data
  useEffect(()=>{
    searchFormRef.current = searchForm;
  }, [searchForm]); 

  //Reset the search criteria when entered from homepage's tags and the search criteria have changed
  useEffect(() => {
    return () => {
      // Note: `searchForm` here stands for the initial search data when the page is first rendered.
      if ((location.state as any)?.fromTag && deepEqual(searchFormRef.current, searchForm)){
        dispatch(propertyListActions.reset());
      }
    };
  }, []);
  
  const districtOptions = { ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions };

  const propertyPropsMap = useMemo(() => (property: CAPropertyStockListItemDTO, soldLeased = false) => ({
    id: property.caPropertyStockId,
    propertyNo: property.propertyNo,
    label: `${multiLang(locale, property.streetZh, property.streetEn, property.streetSc) ?? ''} ${multiLang(locale, property.buildingNameZh, property.buildingNameEn, property.buildingNameSc) ?? ''} ${levelOptions[property.level] ?? ''}`,
    street: multiLang(locale, property.streetZh, property.streetEn, property.streetSc) ?? '',
    buildingName: multiLang(locale, property.buildingNameZh, property.buildingNameEn, property.buildingNameSc) ?? '',
    level: levelOptions[property.level] ?? '',
    image: property.photoFilename ? `${BASE_URL}/files/${property.photoFilename}` : '' , // property.image, 
    net: property.net,
    gross: property.gross,
    district: districtOptions[property.district], //
    price: property.price,
    rent: property.rent,
    status: property.status,
    bookmarked: property.isBookmarked,
    isHotpick: property.isHotPick,
    punchline: getLocalePunchline(property, locale),

    onBookmarkClick: () => {
      if (!loggedIn) {
        jump(jumpers.toLogin());
        return;
      }
      property.isBookmarked ? 
        dispatch(propertyListActions.deleteBookmark({ caPropertyStockId: property.caPropertyStockId })) :
        dispatch(propertyListActions.addBookmark({ caPropertyStockId: property.caPropertyStockId }))
      ;
    },

    onClick: () => {
      if (soldLeased) {
        dispatch(alertConfirmDialogActions.alert(
          '',
          property.status === 'SOLD' ? langProperty.msgSoldClicked : langProperty.msgLeasedClicked,
          lang.actionGoBack, ''
        ));
      } else {
        // FIXME: Different route
        window.open(`properties/${property.propertyNo}`);
      }
    },
  }), [ locale, loggedIn ]);

  const [mergedPropertiesProps, setMergedPropertiesProps] = useState<PropertyResultMapListItemProps[]>([]);

  useEffect(() => {
    const merged = getMergedProperties(
      mapFiltered, soldLeasedProperties,
      propertyPropsMap,
      DISPLAY_FIRST_SOLD_LEASED_PROPERTY_AFTER_N_PROPERTIES, DISPLAY_REST_SOLD_LEASED_PROPERTIES_AFTER_N_PROPERTIES
    );
    setMergedPropertiesProps(merged);
  }, [ mapFiltered ?? EMPTY_ARRAY, soldLeasedProperties ?? EMPTY_ARRAY, propertyPropsMap ]);

  const { saveSearchTitle, saveSearchTitleErrorMsg, openSaveDialog } = useSelector((state: IRootState) => state.saveSearch);
  const maxTitleLength = 16777215;

  const bindSearchTitle = useSimpleFormBinder(
		saveSearchTitle,
		(updated) => dispatch(saveSearchActions.updateSaveSearchTitle(updated ?? '')),
		() => { dispatch(saveSearchActions.updateSaveSearchTitleError(isNonEmpty(saveSearchTitle) ? '' : langProperty.errPleaseEnterTitle)); },
		saveSearchTitleErrorMsg
  );

  const mapPidList = useLocalBinder<number[]>([]);

  const prevLocale = usePrevious<string>(locale);

	useEffect(() => {
    if (prevLocale !== locale) {
      dispatch(propertyListActions.edit("priceDisplay", 
      form.field("priceDisplay").value?.map(v => priceToView(priceFromView(v, prevLocale ?? locale), locale)))
      );
    }
  }, [ locale ]);

  useEffect(() => {
    dispatch(propertyMapActions.fetchPoints());
    dispatch(propertyMapActions.fetchFiltered({ pidList: [], isSwitchingPage: false }));
    dispatch(homeActions.updateSearchType(form.field('buyOrRent').value=='RENT'?'RENT':'BUY'));
  }, [ form.field('buyOrRent').value ]);
  
  return <SearchScreenDesktop
    layoutProps={layoutProps}
    searchScreenProps={{
      bindSearchTitle,
      form: form,

      saveDialogOpen: openSaveDialog,

      usageOptions,
      facingOptions,
      decoOptions,
      viewOptions,
      otherFeatureOptions,
      primarySchoolNetOptions,
      secondarySchoolNetOptions,
      districtHkiOptions,
      districtKltOptions,
      districtNtOptions,
      clubHouseFacilitiesOptions,
      otherOptions,

      onSearchClick: () => {
        dispatch(propertyMapActions.fetchPoints());
        // console.log("print fetchFiltered: onSearchClick");
        dispatch(propertyMapActions.fetchFiltered({ pidList: [], isSwitchingPage: false }));
        form.field('freeTextSearch').change(form.field('freeTextSearch').value?.trim());
        form.field('street').change(form.field('street').value?.trim());
        form.field('buildingName').change(form.field('buildingName').value?.trim());
      },
      onSaveClick: () => {
        if (!loggedIn) {
          jump(jumpers.toLogin());
        } else {
          form.field('freeTextSearch').change(form.field('freeTextSearch').value?.trim());
          form.field('street').change(form.field('street').value?.trim());
          form.field('buildingName').change(form.field('buildingName').value?.trim());
          dispatch(saveSearchActions.updateSaveSearchTitle(getSearchTitle(searchForm, localeState)));
          dispatch(saveSearchActions.openSaveSearchDialog());
        }
      },
      onSaveSubmit: () => {
        if (!isNonEmpty(bindSearchTitle.value)) {
          dispatch(saveSearchActions.updateSaveSearchTitleError(langProperty.errPleaseEnterTitle));
        }
        else if (bindSearchTitle.value!.length > maxTitleLength) {
          dispatch(saveSearchActions.updateSaveSearchTitleError(format(lang.msgMaxLength, maxTitleLength)));
        }
        else {
          dispatch(saveSearchActions.addSearchCriteria());
          dispatch(saveSearchActions.closeSaveSearchDialog());
          dispatch({ type: 'Layout.AlertMessageAdded', payload: { message: langProperty.msgSaveSearchSuccess, severity: "success" } });
        }
      },
      onHideSaveDialog: () => {
        dispatch(saveSearchActions.closeSaveSearchDialog());
      },
      onRequestMore: () => {
        dispatch(propertyMapActions.fetchFiltered({ pidList: mapPidList.value ?? [], isSwitchingPage: true }))
      },
      onResetClick: () => {
        dispatch(propertyListActions.reset());
        dispatch(propertyMapActions.fetchPoints());
        dispatch(propertyMapActions.fetchFiltered({ pidList: [], isSwitchingPage: true }));
      },
    }}

    ordering={{
      direction: searchForm.direction ?? 'DESC',
      sortKey: searchForm.sortKey ?? null,
      onSortSelect: (newSort, newDirection) => {
        dispatch(propertyListActions.edit('sortKey', newSort));
        dispatch(propertyListActions.edit('direction', newDirection));
        // console.log("print fetchFiltered: ordering");
        dispatch(propertyMapActions.fetchFiltered({ pidList: mapPidList.value ?? [], isSwitchingPage: false }));
      }
    }}

    mapViewProps={{
      center: { lat: 22.302711, lng: 114.177216 },
      actualViewHeightPct: 1,
      properties: mapItems,
      onClusterClick: (pidList) => {
        mapPidList.change(pidList);
        dispatch(propertyMapActions.fetchFiltered({ pidList, isSwitchingPage: false }));
      },
      onClusteredPidListChange: (pidList) => {
        mapPidList.change(pidList);
        // console.log("print fetchFiltered: on clusterPidList change");
        dispatch(propertyMapActions.fetchFiltered({ pidList, isSwitchingPage: false }));
      }
    }}

    mapResultProps={{
      layoutProps,
      center: { lat: 22.302711, lng: 114.177216 },
      properties: mergedPropertiesProps,
      filteredLoading,
      filteredHasMore,
      propertyPoints: mapPoints.filter(p => p.lat && p.lng).map(p => ({
        id: p.caPropertyStockId ?? (p as any).propertyStockId,
        lat: p.lat,
        lng: p.lng,
        district: p.district,
      })),
    }}

    onEnquiryClick={() => dispatch(enquiryDialogActions.openDialog())}
  />
}