import { History } from 'history';
import { all, fork } from 'redux-saga/effects';
import agentSaga from './agentSaga';
import customerSaga from './customerSaga';
import homeSaga from './homeSaga';
import initSaga from './initSaga';
import { layoutWatchers } from './layoutSaga';
import loginSaga from './loginSaga';
import newsSaga from './newsSaga';
import notificationSaga from './notificationSaga';
import propertySaga from './propertySaga';
import resetPasswordSaga from './resetPasswordSaga';
import priceChartSaga from './priceChartSaga';

export const rootSaga = function* root(context: PASSagaContext) {
  yield all([
    ...forkWatchers(layoutWatchers, context),
    ...forkWatchers(loginSaga, context),
    ...forkWatchers(agentSaga, context),
    ...forkWatchers(homeSaga, context),
    ...forkWatchers(initSaga, context),
    ...forkWatchers(customerSaga, context),
    ...forkWatchers(resetPasswordSaga, context),
    ...forkWatchers(propertySaga, context),
    ...forkWatchers(priceChartSaga, context),
    ...forkWatchers(notificationSaga, context), 
    ...forkWatchers(newsSaga, context)
  ]);
}

type Task = (...args: any[]) => Generator<any, void, unknown>;

const forkWatchers = (watchers: Task[], context: PASSagaContext) => {
  return watchers.map((watcher) => fork(watcher, context));
}
export interface PASSagaContext {
  browserHistory: History;
}